import { Table } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import ColoredCircle from '../../elements/ColoredCircle/ColoredCircle'
import { PricebookTaxRate } from './PricebookAdmin.gql'
import { usePricebookAdmin } from './hooks/usePricebookAdmin'

export const PricebookTaxRateTable = () => {
  const { taxRateItemClicked, taxRates } = usePricebookAdmin()

  const columns: ColumnsType<PricebookTaxRate> = [
    {
      title: 'Name',
      key: 'name',
      dataIndex: 'name',
      ellipsis: {
        showTitle: true,
      },
      defaultSortOrder: 'ascend',
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: 'Percentage',
      dataIndex: 'rate',
      render: (rate: number) => `${rate * 100}%`,
      sorter: (a, b) => a.rate - b.rate,
      ellipsis: {
        showTitle: true,
      },
    },
    {
      title: 'Status',
      key: 'status',
      render: (item: PricebookTaxRate) => {
        return (
          <div style={{ display: 'flex' }}>
            <ColoredCircle color={item.isActive ? 'limegreen' : 'lightgrey'} />
            <div
              style={{
                display: 'inline-block',
                color: item.isActive ? 'black' : 'lightgrey',
              }}
            >
              {item.isActive ? 'Active' : 'Disabled'}
            </div>
          </div>
        )
      },
      ellipsis: {
        showTitle: true,
      },
      filters: [
        { text: 'Active', value: true },
        { text: 'Disabled', value: false },
      ],
      onFilter: (value, record) => record.isActive === value,
    },
  ]

  if (!taxRates) return null

  return (
    <div>
      <div>
        <span style={{ fontSize: '18px', fontWeight: 'bold' }}>Tax Rates</span>{' '}
        <span style={{ fontSize: '12px', color: 'gray' }}>
          {taxRates.length} items
        </span>
      </div>
      <Table
        rowKey="pricebookTaxRateGuid"
        scroll={{ x: true }}
        pagination={{
          defaultPageSize: 15,
          pageSizeOptions: [15, 30, 100, 250],
        }}
        onRow={(record, rowIndex) => {
          return {
            onClick: () => {
              taxRateItemClicked(record)
            },
          }
        }}
        columns={columns}
        dataSource={taxRates}
      />
    </div>
  )
}
