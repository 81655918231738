import { memo } from 'react'
import { ContactLite } from '../../components/PhoneIntegrated/useFetchContactLiteByPhoneNumber'
import { useTwilioPhone } from '../../components/PhoneIntegrated/useTwilioPhone'
import { useCanUseIntegratedPhone } from '../../hooks/permission/useCanUseIntegratedPhone'

type CallablePhoneLinkProps = {
  phoneNumber: string
  contact?: ContactLite
  children: React.ReactNode
}

export const CallablePhoneLink = memo<CallablePhoneLinkProps>(
  ({ phoneNumber, contact, children }) => {
    const { beginVoiceCallTo } = useTwilioPhone()
    const canUseIntegratedPhone = useCanUseIntegratedPhone()

    const handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
      e.preventDefault()
      beginVoiceCallTo({ phoneNumber, contact })
    }

    return canUseIntegratedPhone ? (
      <a
        href={`tel:${phoneNumber}`}
        onClick={handleClick}
        className="cursor-pointer"
      >
        {children}
      </a>
    ) : (
      <a href={`tel:${phoneNumber}`} target="_blank" rel="noopener noreferrer">
        {children}
      </a>
    )
  },
)
