import { useDescription, useTsController } from '@ts-react/form'
import { Form, Select } from 'antd'
import React from 'react'

import { useAccountReminderAssignmentField } from './AccountReminderAssignmentField.hooks'

const AccountReminderAssignmentField = React.memo(() => {
  const {
    field: { onChange, value },
    error,
  } = useTsController<string>()

  const { label, placeholder } = useDescription()

  const { fetching, companyUserOptions, defaultValue } =
    useAccountReminderAssignmentField()
  return (
    <Form.Item
      label={label}
      required
      validateStatus={error ? 'error' : 'success'}
      help={error?.errorMessage}
      className="account-reminder-split-row-form-item"
    >
      <Select
        placeholder={placeholder}
        allowClear={false}
        value={value ?? defaultValue}
        onChange={(value: string) => onChange(value)}
        className="w-full"
        loading={fetching}
      >
        {companyUserOptions.map(({ label, value }) => {
          return (
            <Select.Option key={value} value={value}>
              {label}
            </Select.Option>
          )
        })}
      </Select>
    </Form.Item>
  )
})

export default AccountReminderAssignmentField
