import { useStringFieldInfo, useTsController } from '@ts-react/form'
import { Form, Input } from 'antd'
import React from 'react'

// TODO: this is a react-ts-form POC. If we choose to use it going forward we should move a lot of this code to reusable
// components.
const TextField = React.memo(() => {
  const {
    field: { onChange, value },
    error,
  } = useTsController<string>()

  const { label, placeholder } = useStringFieldInfo()
  return (
    <Form.Item
      label={label}
      required
      validateStatus={error ? 'error' : 'success'}
      help={error?.errorMessage}
    >
      <Input
        placeholder={placeholder}
        onChange={e => onChange(e.target.value)}
        value={value}
      />
    </Form.Item>
  )
})
export default TextField
