import { Guid, PricebookTaxRateDto } from '@breezy/shared'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useQuery } from 'urql'
import { OnsiteModal } from '../../adam-components/OnsiteModal/OnsiteModal'
import { CloseConfirmModal } from '../../adam-components/OnsiteModal/useCloseConfirmModal'
import { useExpectedCompanyGuid } from '../../providers/PrincipalUser'
import { useModalState } from '../../utils/react-utils'
import { ItemPicker, ItemPickerItem } from './ItemPicker'
import { GET_PRICEBOOK_TAX_RATES } from './PricebookPickers.gql'

type TaxRatePickerProps = {
  preSelectedTaxRateData?: PricebookTaxRateDto
  onCancel: () => void
  onSubmit: (taxRate: PricebookTaxRateDto) => void
}

export const TaxRatePicker = React.memo<TaxRatePickerProps>(
  ({ preSelectedTaxRateData, onSubmit, onCancel }) => {
    const companyGuid = useExpectedCompanyGuid()

    const [selectedItemGuid, setSelectedItemGuid] = useState<Guid | undefined>()

    const [{ data, fetching }] = useQuery({
      query: GET_PRICEBOOK_TAX_RATES,
      variables: {
        companyGuid,
      },
    })

    useEffect(() => {
      if (preSelectedTaxRateData && data?.pricebookTaxRates.length) {
        const taxRate = data.pricebookTaxRates.find(
          taxRate =>
            taxRate.name === preSelectedTaxRateData.name &&
            taxRate.rate === preSelectedTaxRateData.rate,
        )
        if (taxRate) {
          setSelectedItemGuid(taxRate.pricebookTaxRateGuid)
        }
      }
    }, [data?.pricebookTaxRates, preSelectedTaxRateData])

    const items = useMemo<ItemPickerItem[]>(
      () =>
        data?.pricebookTaxRates.map(taxRate => ({
          id: taxRate.pricebookTaxRateGuid,
          name: taxRate.name,
          value: taxRate.rate,
          numberFormatter: (value: number) => `${value * 100}%`,
        })) ?? [],
      [data?.pricebookTaxRates],
    )

    const onItemSelect = useCallback((id: Guid, count: number) => {
      if (count === 0) {
        setSelectedItemGuid(undefined)
      } else {
        setSelectedItemGuid(id)
      }
    }, [])

    const onSave = useCallback(() => {
      const taxRate = data?.pricebookTaxRates.find(
        taxRate => taxRate.pricebookTaxRateGuid === selectedItemGuid,
      )
      if (taxRate) {
        onSubmit(taxRate)
      }
    }, [data?.pricebookTaxRates, onSubmit, selectedItemGuid])

    const selectedItemCountMap = useMemo(
      () => (selectedItemGuid ? { [selectedItemGuid]: 1 } : {}),
      [selectedItemGuid],
    )

    const [confirmCancelOpen, openConfirmCancel, closeConfirmCancel] =
      useModalState()

    const ourOnCancel = useCallback(() => {
      if (!selectedItemGuid) {
        onCancel()
      } else if (
        // If they have a selected item (they must if we're here), but there wasn't pre-selected data, then they have a
        // change (selecting that thing).
        !preSelectedTaxRateData
      ) {
        openConfirmCancel()
      } else if (
        // If the pre-selected isn't what they selected, obviously they changed it
        preSelectedTaxRateData.pricebookTaxRateGuid !== selectedItemGuid
      ) {
        openConfirmCancel()
      } else {
        onCancel()
      }
    }, [onCancel, openConfirmCancel, preSelectedTaxRateData, selectedItemGuid])

    return (
      <OnsiteModal onClose={ourOnCancel} size="large">
        <ItemPicker
          isLoading={fetching}
          items={items}
          title="Edit tax rate"
          itemLabel="Tax Rate"
          addText="Set"
          onCancel={ourOnCancel}
          onSave={onSave}
          selectedItemCountMap={selectedItemCountMap}
          onItemSelect={onItemSelect}
        />
        {confirmCancelOpen && (
          <CloseConfirmModal
            onCancelCancel={closeConfirmCancel}
            onConfirmCancel={onCancel}
          />
        )}
      </OnsiteModal>
    )
  },
)
