import { BzDateFns, IsoDateString, TimeZoneId, bzExpect } from '@breezy/shared'
import { useDescription, useTsController } from '@ts-react/form'
import { Form } from 'antd'
import React from 'react'

import DatePicker from '../../DatePicker/DatePicker'
const DatePickerField = React.memo<{ timeZoneId: TimeZoneId }>(
  ({ timeZoneId }) => {
    const {
      field: { onChange, value },
      error,
    } = useTsController<IsoDateString>()

    const { label } = useDescription()
    return (
      <Form.Item
        label={label}
        required
        validateStatus={error ? 'error' : 'success'}
        help={error?.errorMessage}
        className="account-reminder-split-row-form-item"
      >
        <DatePicker
          allowClear={false}
          value={value ? BzDateFns.parseISO(value, timeZoneId) : undefined}
          onChange={date =>
            onChange(BzDateFns.formatISO(bzExpect(date), timeZoneId))
          }
          format="MMMM D, YYYY"
          className="w-full"
        />
      </Form.Item>
    )
  },
)

export default DatePickerField
