import {
  BulkPricebookCategory,
  BulkPricebookItemRow,
  PricebookItem,
  PricebookItemTypeEnum,
  QBO_ERROR_CODES,
  QboIncomeAccount,
  cloneDeep,
  convertToBulkPricebookCategories,
  parseQboErrorInfo,
} from '@breezy/shared'
import { QueryObserverResult } from '@tanstack/react-query'
import { TRPCClientErrorLike } from '@trpc/client'
import {
  Reducer,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from 'react'
import {
  AiOutlineGold,
  AiOutlineHdd,
  AiOutlineTeam,
  AiOutlineTool,
} from 'react-icons/ai'
import { useQuery } from 'urql'
import {
  FinanceAccountTreeData,
  qboAccountsToTreeData,
} from '../../../hooks/Quickbooks/useQboAccountTreeData'
import { trpc } from '../../../hooks/trpc'
import { useExpectedCompany } from '../../../providers/PrincipalUser'
import { Composable } from '../../../utils/Composable'
import { useModal } from '../../../utils/antd-utils'
import { useQuickbooksAuthorizeButtonProps } from '../../Quickbooks/QuickbooksAuthorizeButton'
import { useIsQboEnabled } from '../../Quickbooks/QuickbooksSyncButton'
import {
  PRICEBOOK_CATEGORIES_ADMIN_QUERY,
  PRICEBOOK_DISCOUNTS_ADMIN_QUERY,
  PRICEBOOK_ITEMS_ADMIN_QUERY,
  PRICEBOOK_TAX_RATES_ADMIN_QUERY,
  PricebookCategoriesItem,
  PricebookDiscount,
  PricebookTaxRate,
} from '../PricebookAdmin.gql'
import { PricebookDiscountTable } from '../PricebookDiscountTable'
import { PricebookItemTable } from '../PricebookItemTable'
import { PricebookTaxRateTable } from '../PricebookTaxRateTable'
import {
  PricebookItemDefaultPhotoMap,
  usePricebookItemDefaultPhotos,
} from './useDefaultPricebookItemPhotos'

type PricebookAdminState = {
  drawerMode: PricebookAdminDrawerMode
  pricebookItem: PricebookItem | undefined
  taxRateItem: PricebookTaxRate | undefined
  discountItem: PricebookDiscount | undefined
  categories: PricebookCategoriesItem[]
  latestAction: PricebookAdminAction | null
  pendingPricebookItems: BulkPricebookItemRow[] | undefined
  pendingPricebookCategories: BulkPricebookCategory[] | undefined
  defaultPhotos: PricebookItemDefaultPhotoMap
}

export enum PricebookManagedType {
  SERVICE = 'Service',
  MATERIAL = 'Material',
  EQUIPMENT = 'Equipment',
  LABOR = 'Labor',
  TAX_RATE = 'Tax Rate',
  DISCOUNT = 'Discount',
  CATEGORY = 'Categories',
}

export type PricebookManagedTypeDetail = {
  type: PricebookManagedType
  singular: string
  plural: string
  isItem: boolean
  excludedFromAddNewItemMenu: boolean
  table?: React.ReactNode
  icon?: React.ReactNode
}

export const pricebookManagedTypeDetails: PricebookManagedTypeDetail[] = [
  {
    type: PricebookManagedType.SERVICE,
    singular: 'Service',
    plural: 'Services',
    isItem: true,
    excludedFromAddNewItemMenu: false,
    table: <PricebookItemTable type={PricebookItemTypeEnum.SERVICE} />,
    icon: <AiOutlineTool />,
  },
  {
    type: PricebookManagedType.MATERIAL,
    singular: 'Material',
    plural: 'Materials',
    isItem: true,
    excludedFromAddNewItemMenu: false,
    table: <PricebookItemTable type={PricebookItemTypeEnum.MATERIAL} />,
    icon: <AiOutlineGold />,
  },
  {
    type: PricebookManagedType.EQUIPMENT,
    singular: 'Equipment',
    plural: 'Equipment',
    excludedFromAddNewItemMenu: false,
    table: <PricebookItemTable type={PricebookItemTypeEnum.EQUIPMENT} />,
    isItem: true,
    icon: <AiOutlineHdd />,
  },
  {
    type: PricebookManagedType.LABOR,
    singular: 'Labor',
    plural: 'Labor',
    isItem: true,
    excludedFromAddNewItemMenu: false,
    table: <PricebookItemTable type={PricebookItemTypeEnum.LABOR} />,
    icon: <AiOutlineTeam />,
  },
  {
    type: PricebookManagedType.TAX_RATE,
    singular: 'Tax Rate',
    plural: 'Tax Rates',
    isItem: false,
    excludedFromAddNewItemMenu: false,
    table: <PricebookTaxRateTable />,
    icon: <AiOutlineTool />,
  },
  {
    type: PricebookManagedType.DISCOUNT,
    singular: 'Discount',
    plural: 'Discounts',
    isItem: false,
    excludedFromAddNewItemMenu: false,
    table: <PricebookDiscountTable />,
    icon: <AiOutlineTool />,
  },
  {
    type: PricebookManagedType.CATEGORY,
    singular: 'Category',
    plural: 'Categories',
    isItem: false,
    table: null,
    excludedFromAddNewItemMenu: true,
    icon: <AiOutlineTool />,
  },
]

export enum PricebookAdminDrawerMode {
  CLOSED,
  MANAGE_CATEGORIES,
  ADD_SERVICE,
  EDIT_SERVICE,
  ADD_MATERIAL,
  EDIT_MATERIAL,
  ADD_EQUIPMENT,
  EDIT_EQUIPMENT,
  ADD_TAX,
  EDIT_TAX,
  ADD_LABOR,
  EDIT_LABOR,
  ADD_DISCOUNT,
  EDIT_DISCOUNT,
  MANAGE_VERSION_HISTORY,
}

const INITIAL_STATE: PricebookAdminState = {
  drawerMode: PricebookAdminDrawerMode.CLOSED,
  pricebookItem: undefined,
  taxRateItem: undefined,
  discountItem: undefined,
  categories: [],
  pendingPricebookItems: [],
  pendingPricebookCategories: [],
  latestAction: null,
  defaultPhotos: {
    [PricebookItemTypeEnum.SERVICE]: undefined,
    [PricebookItemTypeEnum.MATERIAL]: undefined,
    [PricebookItemTypeEnum.EQUIPMENT]: undefined,
    [PricebookItemTypeEnum.LABOR]: undefined,
  },
}

type HookAPI = {
  pricebookItem: PricebookItem | undefined
  taxRateItem: PricebookTaxRate | undefined
  discountItem: PricebookDiscount | undefined
  serviceItemClicked: (item: PricebookItem) => void
  materialItemClicked: (item: PricebookItem) => void
  equipmentItemClicked: (item: PricebookItem) => void
  laborItemClicked: (item: PricebookItem) => void
  taxRateItemClicked: (item: PricebookTaxRate) => void
  discountItemClicked: (item: PricebookDiscount) => void
  startAddNewServiceItemFlow: () => void
  startAddNewMaterialItemFlow: () => void
  startAddNewEquipmentItemFlow: () => void
  startAddNewLaborItemFlow: () => void
  startManageCategoriesFlow: () => void
  startAddNewTaxRateItemFlow: () => void
  startAddNewDiscountItemFlow: () => void
  pricebookItemMutated: () => void
  categoriesMutated: () => void
  pricebookTaxRateMutated: () => void
  pricebookDiscountMutated: () => void
  closeDrawer: () => void
  drawerMode: PricebookAdminDrawerMode
  categories: PricebookCategoriesItem[] | undefined
  pricebookItems: PricebookItem[] | undefined
  pendingPricebookItems: BulkPricebookItemRow[] | undefined
  pendingPricebookCategories: BulkPricebookCategory[] | undefined
  taxRates: PricebookTaxRate[] | undefined
  discountItems: PricebookDiscount[] | undefined
  qboIncomeAccountTreeData?: FinanceAccountTreeData[]
  qboIncomeAccountsLoading?: boolean
  qboIncomeAccounts?: QboIncomeAccount[]
  refetchQboIncomeAccounts: () => Promise<
    // I need the type to give me error.message, but I really don't care about anything else about it.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    QueryObserverResult<unknown, TRPCClientErrorLike<any>>
  >
  qboAuthorized: boolean
  bulkPricebookImportSetPendingItems: (items: BulkPricebookItemRow[]) => void
  bulkPricebookImportSetPendingCategories: (
    categories: BulkPricebookCategory[],
  ) => void
  bulkPricebookImportClear: () => void
  refetchAll: () => void
  pricebookVersionHistoryClicked: () => void
  defaultPhotos: PricebookItemDefaultPhotoMap
}

export enum PricebookAdminActionType {
  PRICEBOOK_SERVICE_ITEM_CLICKED = 'PRICEBOOK_SERVICE_ITEM_CLICKED',
  PRICEBOOK_MATERIAL_ITEM_CLICKED = 'PRICEBOOK_MATERIAL_ITEM_CLICKED',
  PRICEBOOK_EQUIPMENT_ITEM_CLICKED = 'PRICEBOOK_EQUIPMENT_ITEM_CLICKED',
  PRICEBOOK_LABOR_ITEM_CLICKED = 'PRICEBOOK_LABOR_ITEM_CLICKED',
  PRICEBOOK_TAX_RATE_ITEM_CLICKED = 'PRICEBOOK_TAX_RATE_ITEM_CLICKED',
  PRICEBOOK_DISCOUNT_ITEM_CLICKED = 'PRICEBOOK_DISCOUNT_ITEM_CLICKED',
  CLOSE_DRAWER = 'CLOSE_DRAWER',
  PRICEBOOK_ITEM_MUTATED = 'PRICEBOOK_ITEM_MUTATED',
  PRICEBOOK_TAX_RATE_MUTATED = 'PRICEBOOK_TAX_RATE_MUTATED',
  PRICEBOOK_DISCOUNT_MUTATED = 'PRICEBOOK_DISCOUNT_MUTATED',
  CATEGORIES_MUTATED = 'CATEGORIES_MUTATED',
  START_ADD_NEW_SERVICE_ITEM_FLOW = 'START_ADD_NEW_SERVICE_ITEM_FLOW',
  START_ADD_NEW_MATERIAL_ITEM_FLOW = 'START_ADD_NEW_MATERIAL_ITEM_FLOW',
  START_ADD_NEW_EQUIPMENT_ITEM_FLOW = 'START_ADD_NEW_EQUIPMENT_ITEM_FLOW',
  START_ADD_NEW_LABOR_ITEM_FLOW = 'START_ADD_NEW_LABOR_ITEM_FLOW',
  START_ADD_NEW_TAX_RATE_ITEM_FLOW = 'START_ADD_NEW_TAX_RATE_ITEM_FLOW',
  START_ADD_NEW_DISCOUNT_ITEM_FLOW = 'START_ADD_NEW_DISCOUNT_ITEM_FLOW',
  START_MANAGE_CATEGORIES_FLOW = 'START_MANAGE_CATEGORIES_FLOW',

  // Bulk import
  BULK_PRICEBOOK_IMPORT_SET_PENDING_ITEMS = 'BULK_PRICEBOOK_IMPORT_SET_PENDING_ITEMS',
  BULK_PRICEBOOK_IMPORT_SET_PENDING_CATEGORIES = 'BULK_PRICEBOOK_IMPORT_SET_PENDING_CATEGORIES',
  BULK_PRICEBOOK_CLEAR = 'BULK_PRICEBOOK_CLEAR',

  VERSION_HISTORY_CLICKED = 'VERSION_HISTORY_CLICKED',
}

type PricebookAdminServiceItemClicked = {
  type: PricebookAdminActionType.PRICEBOOK_SERVICE_ITEM_CLICKED
  payload: PricebookItem
}

type PricebookAdminMaterialItemClicked = {
  type: PricebookAdminActionType.PRICEBOOK_MATERIAL_ITEM_CLICKED
  payload: PricebookItem
}

type PricebookAdminEquipmentItemClicked = {
  type: PricebookAdminActionType.PRICEBOOK_EQUIPMENT_ITEM_CLICKED
  payload: PricebookItem
}

type PricebookAdminLaborItemClicked = {
  type: PricebookAdminActionType.PRICEBOOK_LABOR_ITEM_CLICKED
  payload: PricebookItem
}

type PricebookAdminTaxRateItemClicked = {
  type: PricebookAdminActionType.PRICEBOOK_TAX_RATE_ITEM_CLICKED
  payload: PricebookTaxRate
}

type PricebookAdminDiscountItemClicked = {
  type: PricebookAdminActionType.PRICEBOOK_DISCOUNT_ITEM_CLICKED
  payload: PricebookDiscount
}

type NullPayloadPricebookAdminActionType<T extends PricebookAdminActionType> = {
  type: T
  payload: null
}
type PricebookAdminCloseDrawer =
  NullPayloadPricebookAdminActionType<PricebookAdminActionType.CLOSE_DRAWER>
type PricebookAdminItemMutated =
  NullPayloadPricebookAdminActionType<PricebookAdminActionType.PRICEBOOK_ITEM_MUTATED>

type PricebookAdminTaxRateMutated =
  NullPayloadPricebookAdminActionType<PricebookAdminActionType.PRICEBOOK_TAX_RATE_MUTATED>

type PricebookAdminDiscountMutated =
  NullPayloadPricebookAdminActionType<PricebookAdminActionType.PRICEBOOK_DISCOUNT_MUTATED>

type PricebookAdminCategoriesMutated =
  NullPayloadPricebookAdminActionType<PricebookAdminActionType.CATEGORIES_MUTATED>

type PricebookAdminStartAddNewServiceItemFlow =
  NullPayloadPricebookAdminActionType<PricebookAdminActionType.START_ADD_NEW_SERVICE_ITEM_FLOW>

type PricebookAdminStartAddNewMaterialItemFlow =
  NullPayloadPricebookAdminActionType<PricebookAdminActionType.START_ADD_NEW_MATERIAL_ITEM_FLOW>

type PricebookAdminStartAddNewEquipmentItemFlow =
  NullPayloadPricebookAdminActionType<PricebookAdminActionType.START_ADD_NEW_EQUIPMENT_ITEM_FLOW>

type PricebookAdminStartAddNewLaborItemFlow =
  NullPayloadPricebookAdminActionType<PricebookAdminActionType.START_ADD_NEW_LABOR_ITEM_FLOW>

type PricebookAdminStartAddNewTaxRateItemFlow =
  NullPayloadPricebookAdminActionType<PricebookAdminActionType.START_ADD_NEW_TAX_RATE_ITEM_FLOW>

type PricebookAdminStartAddNewDiscountItemFlow =
  NullPayloadPricebookAdminActionType<PricebookAdminActionType.START_ADD_NEW_DISCOUNT_ITEM_FLOW>

type PricebookAdminStartManageCategoriesFlow =
  NullPayloadPricebookAdminActionType<PricebookAdminActionType.START_MANAGE_CATEGORIES_FLOW>

type BulkPricebookImportSetPendingItems = {
  type: PricebookAdminActionType.BULK_PRICEBOOK_IMPORT_SET_PENDING_ITEMS
  payload: BulkPricebookItemRow[]
}

type BulkPricebookImportSetPendingCategories = {
  type: PricebookAdminActionType.BULK_PRICEBOOK_IMPORT_SET_PENDING_CATEGORIES
  payload: BulkPricebookCategory[]
}

type BulkPricebookImportClear =
  NullPayloadPricebookAdminActionType<PricebookAdminActionType.BULK_PRICEBOOK_CLEAR>

type PricebookVersionHistoryClicked =
  NullPayloadPricebookAdminActionType<PricebookAdminActionType.VERSION_HISTORY_CLICKED>

type PricebookAdminAction =
  | PricebookAdminServiceItemClicked
  | PricebookAdminMaterialItemClicked
  | PricebookAdminEquipmentItemClicked
  | PricebookAdminLaborItemClicked
  | PricebookAdminDiscountItemClicked
  | PricebookAdminCloseDrawer
  | PricebookAdminItemMutated
  | PricebookAdminCategoriesMutated
  | PricebookAdminTaxRateMutated
  | PricebookAdminStartAddNewServiceItemFlow
  | PricebookAdminStartAddNewMaterialItemFlow
  | PricebookAdminStartManageCategoriesFlow
  | PricebookAdminStartAddNewEquipmentItemFlow
  | PricebookAdminStartAddNewLaborItemFlow
  | PricebookAdminTaxRateItemClicked
  | PricebookAdminStartAddNewTaxRateItemFlow
  | PricebookAdminStartAddNewDiscountItemFlow
  | PricebookAdminDiscountMutated
  | BulkPricebookImportSetPendingItems
  | BulkPricebookImportSetPendingCategories
  | BulkPricebookImportClear
  | PricebookVersionHistoryClicked

const reducer: Reducer<PricebookAdminState, PricebookAdminAction> = (
  state,
  action,
) => {
  const nextState = cloneDeep(state)
  nextState.latestAction = { ...action }

  const { type, payload } = action
  switch (type) {
    case PricebookAdminActionType.PRICEBOOK_SERVICE_ITEM_CLICKED:
      return {
        ...nextState,
        drawerMode: PricebookAdminDrawerMode.EDIT_SERVICE,
        pricebookItem: payload,
      }
    case PricebookAdminActionType.PRICEBOOK_MATERIAL_ITEM_CLICKED:
      return {
        ...nextState,
        drawerMode: PricebookAdminDrawerMode.EDIT_MATERIAL,
        pricebookItem: payload,
      }
    case PricebookAdminActionType.PRICEBOOK_EQUIPMENT_ITEM_CLICKED:
      return {
        ...nextState,
        drawerMode: PricebookAdminDrawerMode.EDIT_EQUIPMENT,
        pricebookItem: payload,
      }
    case PricebookAdminActionType.PRICEBOOK_LABOR_ITEM_CLICKED:
      return {
        ...nextState,
        drawerMode: PricebookAdminDrawerMode.EDIT_LABOR,
        pricebookItem: payload,
      }
    case PricebookAdminActionType.PRICEBOOK_TAX_RATE_ITEM_CLICKED:
      return {
        ...nextState,
        drawerMode: PricebookAdminDrawerMode.EDIT_TAX,
        taxRateItem: payload,
      }
    case PricebookAdminActionType.PRICEBOOK_DISCOUNT_ITEM_CLICKED:
      return {
        ...nextState,
        drawerMode: PricebookAdminDrawerMode.EDIT_DISCOUNT,
        discountItem: payload,
      }
    case PricebookAdminActionType.CLOSE_DRAWER:
      return {
        ...nextState,
        drawerMode: PricebookAdminDrawerMode.CLOSED,
        pricebookItem: undefined,
        discountItem: undefined,
        taxRateItem: undefined,
      }
    case PricebookAdminActionType.PRICEBOOK_ITEM_MUTATED:
      return {
        ...nextState,
        drawerMode: PricebookAdminDrawerMode.CLOSED,
        pricebookItem: undefined,
      }
    case PricebookAdminActionType.PRICEBOOK_TAX_RATE_MUTATED:
      return {
        ...nextState,
        drawerMode: PricebookAdminDrawerMode.CLOSED,
        pricebookItem: undefined,
      }
    case PricebookAdminActionType.PRICEBOOK_DISCOUNT_MUTATED:
      return {
        ...nextState,
        drawerMode: PricebookAdminDrawerMode.CLOSED,
        pricebookItem: undefined,
      }
    case PricebookAdminActionType.CATEGORIES_MUTATED:
      return {
        ...nextState,
      }
    case PricebookAdminActionType.START_ADD_NEW_SERVICE_ITEM_FLOW:
      return {
        ...nextState,
        drawerMode: PricebookAdminDrawerMode.ADD_SERVICE,
      }
    case PricebookAdminActionType.START_ADD_NEW_MATERIAL_ITEM_FLOW:
      return {
        ...nextState,
        drawerMode: PricebookAdminDrawerMode.ADD_MATERIAL,
      }
    case PricebookAdminActionType.START_ADD_NEW_EQUIPMENT_ITEM_FLOW:
      return {
        ...nextState,
        drawerMode: PricebookAdminDrawerMode.ADD_EQUIPMENT,
      }
    case PricebookAdminActionType.START_ADD_NEW_LABOR_ITEM_FLOW:
      return {
        ...nextState,
        drawerMode: PricebookAdminDrawerMode.ADD_LABOR,
      }
    case PricebookAdminActionType.START_ADD_NEW_TAX_RATE_ITEM_FLOW:
      return {
        ...nextState,
        drawerMode: PricebookAdminDrawerMode.ADD_TAX,
      }
    case PricebookAdminActionType.START_ADD_NEW_DISCOUNT_ITEM_FLOW:
      return {
        ...nextState,
        drawerMode: PricebookAdminDrawerMode.ADD_DISCOUNT,
      }
    case PricebookAdminActionType.START_MANAGE_CATEGORIES_FLOW:
      return {
        ...nextState,
        drawerMode: PricebookAdminDrawerMode.MANAGE_CATEGORIES,
      }

    // Bulk import
    case PricebookAdminActionType.BULK_PRICEBOOK_IMPORT_SET_PENDING_ITEMS:
      return {
        ...nextState,
        pendingPricebookItems: payload,
      }

    case PricebookAdminActionType.BULK_PRICEBOOK_IMPORT_SET_PENDING_CATEGORIES:
      return {
        ...nextState,
        pendingPricebookCategories: payload,
      }

    case PricebookAdminActionType.BULK_PRICEBOOK_CLEAR:
      return {
        ...nextState,
        pendingPricebookItems: [],
        // reset the pending categories to the original state
        pendingPricebookCategories: convertToBulkPricebookCategories(
          state.categories,
        ),
      }

    case PricebookAdminActionType.VERSION_HISTORY_CLICKED:
      return {
        ...nextState,
        drawerMode: PricebookAdminDrawerMode.MANAGE_VERSION_HISTORY,
      }

    default:
      throw new Error(`MissingCaseException: ${type}`)
  }
}

export const PricebookAdminContext = createContext<HookAPI>({
  pricebookItem: undefined,
  taxRateItem: undefined,
  discountItem: undefined,
  serviceItemClicked: () => undefined,
  materialItemClicked: () => undefined,
  equipmentItemClicked: () => undefined,
  laborItemClicked: () => undefined,
  taxRateItemClicked: () => undefined,
  discountItemClicked: () => undefined,
  pricebookItemMutated: () => undefined,
  pricebookTaxRateMutated: () => undefined,
  categoriesMutated: () => undefined,
  startAddNewServiceItemFlow: () => undefined,
  startAddNewMaterialItemFlow: () => undefined,
  startAddNewEquipmentItemFlow: () => undefined,
  startAddNewLaborItemFlow: () => undefined,
  startManageCategoriesFlow: () => undefined,
  startAddNewTaxRateItemFlow: () => undefined,
  startAddNewDiscountItemFlow: () => undefined,
  pricebookDiscountMutated: () => undefined,
  closeDrawer: () => undefined,
  drawerMode: PricebookAdminDrawerMode.CLOSED,
  categories: undefined,
  pricebookItems: undefined,
  pendingPricebookItems: undefined,
  pendingPricebookCategories: undefined,
  taxRates: undefined,
  discountItems: undefined,
  bulkPricebookImportSetPendingItems: () => undefined,
  bulkPricebookImportSetPendingCategories: () => undefined,
  bulkPricebookImportClear: () => undefined,
  refetchAll: () => undefined,
  pricebookVersionHistoryClicked: () => undefined,
  defaultPhotos: {
    [PricebookItemTypeEnum.SERVICE]: undefined,
    [PricebookItemTypeEnum.MATERIAL]: undefined,
    [PricebookItemTypeEnum.EQUIPMENT]: undefined,
    [PricebookItemTypeEnum.LABOR]: undefined,
  },
  qboAuthorized: false,
  refetchQboIncomeAccounts: () =>
    Promise.resolve(
      {} as Awaited<ReturnType<HookAPI['refetchQboIncomeAccounts']>>,
    ),
})

export const PricebookAdminProvider = ({ children }: Composable) => {
  const [state, dispatch] = useReducer(reducer, cloneDeep(INITIAL_STATE))

  const { companyGuid } = useExpectedCompany()

  const [pricebookItemsQuery, pricebookItemsQueryRefetch] = useQuery({
    query: PRICEBOOK_ITEMS_ADMIN_QUERY,
    variables: { companyGuid },
  })

  const [pricebookCategoriesQuery, pricebookCategoriesQueryRefetch] = useQuery({
    query: PRICEBOOK_CATEGORIES_ADMIN_QUERY,
    variables: { companyGuid },
  })

  const [pricebookTaxRatesQuery, pricebookTaxRatesQueryRefetch] = useQuery({
    query: PRICEBOOK_TAX_RATES_ADMIN_QUERY,
    variables: { companyGuid },
  })

  const { defaultPhotos } = usePricebookItemDefaultPhotos()

  const [pricebookDiscountsQuery, pricebookDiscountsQueryRefetch] = useQuery({
    query: PRICEBOOK_DISCOUNTS_ADMIN_QUERY,
    variables: { companyGuid },
  })

  const serviceItemClicked = (item: PricebookItem) => {
    dispatch({
      type: PricebookAdminActionType.PRICEBOOK_SERVICE_ITEM_CLICKED,
      payload: item,
    })
  }

  const materialItemClicked = (item: PricebookItem) => {
    dispatch({
      type: PricebookAdminActionType.PRICEBOOK_MATERIAL_ITEM_CLICKED,
      payload: item,
    })
  }

  const equipmentItemClicked = (item: PricebookItem) => {
    dispatch({
      type: PricebookAdminActionType.PRICEBOOK_EQUIPMENT_ITEM_CLICKED,
      payload: item,
    })
  }

  const laborItemClicked = (item: PricebookItem) => {
    dispatch({
      type: PricebookAdminActionType.PRICEBOOK_LABOR_ITEM_CLICKED,
      payload: item,
    })
  }

  const taxRateItemClicked = (item: PricebookTaxRate) => {
    dispatch({
      type: PricebookAdminActionType.PRICEBOOK_TAX_RATE_ITEM_CLICKED,
      payload: item,
    })
  }

  const discountItemClicked = (item: PricebookDiscount) => {
    dispatch({
      type: PricebookAdminActionType.PRICEBOOK_DISCOUNT_ITEM_CLICKED,
      payload: item,
    })
  }

  const pricebookItemMutated = () => {
    dispatch({
      type: PricebookAdminActionType.PRICEBOOK_ITEM_MUTATED,
      payload: null,
    })
  }

  const categoriesMutated = () => {
    dispatch({
      type: PricebookAdminActionType.CATEGORIES_MUTATED,
      payload: null,
    })
  }

  const closeDrawer = () => {
    dispatch({
      type: PricebookAdminActionType.CLOSE_DRAWER,
      payload: null,
    })
  }

  const startAddNewServiceItemFlow = () => {
    dispatch({
      type: PricebookAdminActionType.START_ADD_NEW_SERVICE_ITEM_FLOW,
      payload: null,
    })
  }

  const startAddNewMaterialItemFlow = () => {
    dispatch({
      type: PricebookAdminActionType.START_ADD_NEW_MATERIAL_ITEM_FLOW,
      payload: null,
    })
  }

  const startAddNewEquipmentItemFlow = () => {
    dispatch({
      type: PricebookAdminActionType.START_ADD_NEW_EQUIPMENT_ITEM_FLOW,
      payload: null,
    })
  }

  const startAddNewLaborItemFlow = () => {
    dispatch({
      type: PricebookAdminActionType.START_ADD_NEW_LABOR_ITEM_FLOW,
      payload: null,
    })
  }

  const startAddNewTaxRateItemFlow = () => {
    dispatch({
      type: PricebookAdminActionType.START_ADD_NEW_TAX_RATE_ITEM_FLOW,
      payload: null,
    })
  }

  const startAddNewDiscountItemFlow = () => {
    dispatch({
      type: PricebookAdminActionType.START_ADD_NEW_DISCOUNT_ITEM_FLOW,
      payload: null,
    })
  }

  const startManageCategoriesFlow = () => {
    dispatch({
      type: PricebookAdminActionType.START_MANAGE_CATEGORIES_FLOW,
      payload: null,
    })
  }

  const pricebookTaxRateMutated = () => {
    dispatch({
      type: PricebookAdminActionType.PRICEBOOK_TAX_RATE_MUTATED,
      payload: null,
    })
  }

  const pricebookDiscountMutated: () => void = () => {
    dispatch({
      type: PricebookAdminActionType.PRICEBOOK_DISCOUNT_MUTATED,
      payload: null,
    })
  }

  const bulkPricebookImportSetPendingItems = (
    items: BulkPricebookItemRow[],
  ) => {
    dispatch({
      type: PricebookAdminActionType.BULK_PRICEBOOK_IMPORT_SET_PENDING_ITEMS,
      payload: items,
    })
  }

  const bulkPricebookImportSetPendingCategories = (
    categories: BulkPricebookCategory[],
  ) => {
    dispatch({
      type: PricebookAdminActionType.BULK_PRICEBOOK_IMPORT_SET_PENDING_CATEGORIES,
      payload: categories,
    })
  }

  const bulkPricebookImportClear = () => {
    dispatch({
      type: PricebookAdminActionType.BULK_PRICEBOOK_CLEAR,
      payload: null,
    })
  }

  const pricebookVersionHistoryClicked = () => {
    dispatch({
      type: PricebookAdminActionType.VERSION_HISTORY_CLICKED,
      payload: null,
    })
  }

  useEffect(() => {
    if (!state.latestAction) return

    switch (state.latestAction.type) {
      case PricebookAdminActionType.CATEGORIES_MUTATED:
        pricebookCategoriesQueryRefetch()
        break
      case PricebookAdminActionType.PRICEBOOK_ITEM_MUTATED:
        pricebookItemsQueryRefetch()
        break
      case PricebookAdminActionType.PRICEBOOK_TAX_RATE_MUTATED:
        pricebookTaxRatesQueryRefetch()
        break
      case PricebookAdminActionType.PRICEBOOK_DISCOUNT_MUTATED:
        pricebookDiscountsQueryRefetch()
        break
      default:
        break
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.latestAction])

  const isQboEnabled = useIsQboEnabled()

  const qboAuthorizeButtonProps = useQuickbooksAuthorizeButtonProps()

  const incomeAccountQuery = trpc.qbo[
    'finance-app:get-income-accounts'
  ].useQuery(undefined, {
    enabled: false,
    retry: false,
    onError: () => {
      // noop. We handle the errors manually in `fetchQboIncomeAccountTreeData`
    },
  })

  const Modal = useModal()

  const [qboAuthorized, setQboAuthorized] = useState(true)

  // Explicitly fetch the QBO income account data. We do it this way so we can properly
  // handle the unauthorized modal.
  useEffect(() => {
    // If they don't have QBO enabled, we're already loading, we've already fetched,
    // or we have an error, skip the fetch.
    if (
      !isQboEnabled ||
      incomeAccountQuery.data ||
      incomeAccountQuery.isError ||
      incomeAccountQuery.isFetching
    ) {
      return
    }

    ;(async () => {
      // Fetch the data
      const res = await incomeAccountQuery.refetch()
      // If there was an error, we'll check if it's an unauth one. Otherwise we'll fall
      // back to reporting it like a normal error.
      if (res.isError) {
        const qboErrorInfo = parseQboErrorInfo(res.error.message)
        let content = 'Please try again later or contact support.'

        // If it's specifically a finance app error, we can parse it
        if (qboErrorInfo) {
          const [errorCode, message] = qboErrorInfo

          // If it's the unauthorized message, show the connect-and-retry modal
          if (errorCode === QBO_ERROR_CODES.AUTH) {
            setQboAuthorized(false)
            return
          }
          // Otherwise, we'll put the parsed message in the error modal
          content = message
        }
        console.error(JSON.stringify(res.error, null, 2))

        Modal.error({
          title: 'Could not fetch Quickbooks income accounts',
          content,
        })
      }
    })()
  }, [Modal, incomeAccountQuery, isQboEnabled, qboAuthorizeButtonProps])

  const qboIncomeAccountTreeData = useMemo(() => {
    const { data, isLoading } = incomeAccountQuery

    if (!isQboEnabled || isLoading || !data) {
      return []
    }

    const parentItems = qboAccountsToTreeData(data)
    return parentItems
  }, [incomeAccountQuery, isQboEnabled])

  const qboIncomeAccounts = useMemo(() => {
    const { data, isLoading } = incomeAccountQuery

    if (!isQboEnabled || isLoading || !data) {
      return []
    }

    return data
  }, [incomeAccountQuery, isQboEnabled])

  const refetchAll = () => {
    pricebookItemsQueryRefetch()
    pricebookCategoriesQueryRefetch()
    pricebookTaxRatesQueryRefetch()
    pricebookDiscountsQueryRefetch()
    incomeAccountQuery.refetch()
  }

  return (
    <PricebookAdminContext.Provider
      value={{
        drawerMode: state.drawerMode,
        pricebookItem: state.pricebookItem,
        taxRateItem: state.taxRateItem,
        discountItem: state.discountItem,
        pendingPricebookItems: state.pendingPricebookItems,
        pendingPricebookCategories: state.pendingPricebookCategories,
        serviceItemClicked,
        pricebookItemMutated,
        materialItemClicked,
        equipmentItemClicked,
        laborItemClicked,
        taxRateItemClicked,
        discountItemClicked,
        categoriesMutated,
        startManageCategoriesFlow,
        startAddNewServiceItemFlow,
        startAddNewMaterialItemFlow,
        startAddNewEquipmentItemFlow,
        startAddNewLaborItemFlow,
        startAddNewTaxRateItemFlow,
        startAddNewDiscountItemFlow,
        pricebookTaxRateMutated,
        pricebookDiscountMutated,
        closeDrawer,
        pricebookItems: pricebookItemsQuery.data?.pricebookItems.map(p => ({
          ...p,
          pricebookCategoryGuid: p.pricebookCategory?.pricebookCategoryGuid,
          qboIncomeAccountId: p.qboIncomeAccount?.qboIncomeAccountId,
          sourcePhotoGuid: p.sourcePhotoGuid,
          sourcePhotoUrl: p.sourcePhoto?.cdnUrl,
          photoGuid: p.photoGuid,
          photoUrl: p.photo?.cdnUrl,
        })),
        categories: pricebookCategoriesQuery.data?.pricebookCategories.map(
          c => ({
            ...c,
            sourcePhotoGuid: c.sourcePhotoGuid,
            sourcePhotoUrl: c.sourcePhoto?.cdnUrl,
            photoGuid: c.photoGuid,
            photoUrl: c.photo?.cdnUrl,
          }),
        ),
        taxRates: pricebookTaxRatesQuery.data?.pricebookTaxRates,
        discountItems: pricebookDiscountsQuery.data?.pricebookDiscounts,
        qboIncomeAccountTreeData,
        qboIncomeAccountsLoading: incomeAccountQuery.isLoading,
        qboIncomeAccounts,
        qboAuthorized,
        refetchQboIncomeAccounts: incomeAccountQuery.refetch,
        bulkPricebookImportSetPendingItems,
        bulkPricebookImportSetPendingCategories,
        bulkPricebookImportClear,
        refetchAll,
        pricebookVersionHistoryClicked,
        defaultPhotos,
      }}
    >
      {children}
    </PricebookAdminContext.Provider>
  )
}

export const usePricebookAdmin = () => useContext(PricebookAdminContext)
