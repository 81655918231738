import { DocumentType, gql } from '../../generated'

export const PRICEBOOK_ITEMS_ADMIN_QUERY = gql(/* GraphQL */ `
  query GetPricebookItemsAdmin($companyGuid: uuid!) {
    pricebookItems(
      where: { companyGuid: { _eq: $companyGuid }, isDeleted: { _eq: false } }
    ) {
      pricebookItemGuid
      companyGuid
      name
      description
      itemType
      imageUrl
      costUSD: costUsd
      priceUSD: priceUsd
      isTaxable
      version
      isActive
      isDiscountable
      sourcePhotoGuid
      sourcePhoto {
        cdnUrl
      }
      photoGuid
      photo {
        cdnUrl
      }
      pricebookCategory {
        pricebookCategoryGuid
        name
        parentCategoryGuid
      }
      qboIncomeAccount: pricebookItemsQboIncomeAccount {
        qboIncomeAccountId
      }
    }
    pricebookCategories(where: { companyGuid: { _eq: $companyGuid } }) {
      pricebookCategoryGuid
      name
      parentCategoryGuid
      sourcePhotoGuid
      sourcePhoto {
        cdnUrl
      }
      photoGuid
      photo {
        cdnUrl
      }
    }
  }
`)

export type PricebookItemsAdmin = DocumentType<
  typeof PRICEBOOK_ITEMS_ADMIN_QUERY
>

export const PRICEBOOK_CATEGORIES_ADMIN_QUERY = gql(/* GraphQL */ `
  query GetPricebookCategoriesAdmin($companyGuid: uuid!) {
    pricebookCategories(where: { companyGuid: { _eq: $companyGuid } }) {
      pricebookCategoryGuid
      name
      parentCategoryGuid
      parentPricebookCategory {
        name
        pricebookCategoryGuid
        parentCategoryGuid
      }
      sourcePhotoGuid
      sourcePhoto {
        cdnUrl
      }
      photoGuid
      photo {
        cdnUrl
      }
    }
  }
`)

type PricebookCategories = DocumentType<
  typeof PRICEBOOK_CATEGORIES_ADMIN_QUERY
>['pricebookCategories']
export type PricebookCategoriesItem = DocumentType<
  typeof PRICEBOOK_CATEGORIES_ADMIN_QUERY
>['pricebookCategories'][number]
export type PricebookCategory = PricebookCategoriesItem & {
  children?: PricebookCategories
}

export const PRICEBOOK_TAX_RATES_ADMIN_QUERY = gql(/* GraphQL */ `
  query GetPricebookTaxRatesAdmin($companyGuid: uuid!) {
    pricebookTaxRates(where: { companyGuid: { _eq: $companyGuid } }) {
      pricebookTaxRateGuid
      companyGuid
      name
      rate
      isActive
      version
    }
  }
`)

export type PricebookTaxRate = DocumentType<
  typeof PRICEBOOK_TAX_RATES_ADMIN_QUERY
>['pricebookTaxRates'][number]

export const PRICEBOOK_DISCOUNTS_ADMIN_QUERY = gql(/* GraphQL */ `
  query GetPricebookDiscountsAdmin($companyGuid: uuid!) {
    pricebookDiscounts(where: { companyGuid: { _eq: $companyGuid } }) {
      pricebookDiscountGuid
      companyGuid
      name
      type
      discountAmountUsd
      discountRate
      description
      isActive
      version
    }
  }
`)

export type PricebookDiscount = DocumentType<
  typeof PRICEBOOK_DISCOUNTS_ADMIN_QUERY
>['pricebookDiscounts'][number]
