import { ConfigProvider, Tag } from 'antd'
import React from 'react'
import { BehindFeatureFlag } from '../../components/BehindFeatureFlag'
import { PricebookPhotoThumbnail } from '../../components/PricebookPhotoThumbnail/PricebookPhotoThumbnail'
import { HtmlRenderer } from '../../elements/HtmlRenderer/HtmlRenderer'
import { OptionContainer } from './OptionContainer'
import { DEFAULT_ESTIMATE_OPTION_NAME, Option } from './estimatesFlowUtils'

export type BasicEstimateOptionProps = {
  option: Option
  index: number
  className?: string
  style?: React.CSSProperties
  hideRecommendedStyling?: boolean
  footer?: React.ReactNode
  subtotalCollapsible?: boolean
  anchorFooter?: boolean
  showLoanDisclosure?: boolean
  showPromoPrequal?: boolean
  noBorder?: boolean
  hideFeaturedThumbnail?: boolean
  customerFacing?: boolean
}

export const BasicEstimateOption = React.memo<BasicEstimateOptionProps>(
  ({
    option,
    index,
    className,
    style,
    hideRecommendedStyling,
    footer,
    subtotalCollapsible,
    anchorFooter,
    showLoanDisclosure,
    showPromoPrequal,
    noBorder,
    hideFeaturedThumbnail,
    customerFacing,
  }) => {
    const {
      displayName,
      descriptionHtml,
      lineItems,
      discounts,
      recommended,
      featuredPhotoCdnUrl,
    } = option

    const resolvedDisplayName = displayName || DEFAULT_ESTIMATE_OPTION_NAME

    const showRecommended = recommended && !hideRecommendedStyling

    return (
      <OptionContainer
        noBorder={noBorder}
        customerFacing={customerFacing}
        showLoanDisclosure={showLoanDisclosure}
        showPromoPrequal={showPromoPrequal}
        recommended={showRecommended}
        className={className}
        style={style}
        lineItems={lineItems}
        discounts={discounts}
        subtotalCollapsible={subtotalCollapsible}
        header={
          <div className="flex flex-row">
            {!hideFeaturedThumbnail && (
              <BehindFeatureFlag
                enabledFeatureFlag="pricebook-photos"
                render={
                  <div>
                    <PricebookPhotoThumbnail
                      large
                      cdnUrl={featuredPhotoCdnUrl}
                    />
                  </div>
                }
                fallback={<></>}
              />
            )}
            <div className="flex flex-col justify-center">
              <div className="flex flex-row items-center text-xl font-semibold">
                #{index + 1}: {resolvedDisplayName}{' '}
                {showRecommended && (
                  <ConfigProvider
                    theme={{
                      token: {
                        colorBorder: '#1677FF',
                      },
                      components: {
                        Tag: {
                          defaultBg: '#E6F4FF',
                          defaultColor: '#1677FF',
                        },
                      },
                    }}
                  >
                    <Tag className="ml-2 font-normal">Recommended</Tag>
                  </ConfigProvider>
                )}
              </div>
              {descriptionHtml && (
                <div className="mt-2 text-sm">
                  <HtmlRenderer htmlContent={descriptionHtml} />
                </div>
              )}
            </div>
          </div>
        }
        footer={footer}
        anchorFooter={anchorFooter}
      />
    )
  },
)
