import { faPhone } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Tooltip } from 'antd'
import { gray7 } from '../../../themes/theme'
import './PhoneNumberView.less'

export type PhoneNumberViewProps = {
  phoneNumber: string
  iconOnly?: boolean
  displayType?: 'compact' | 'circle-button'
}

export const CompactPhoneNumberView = ({
  phoneNumber,
  iconOnly,
}: PhoneNumberViewProps) => {
  const phoneLink = phoneNumber.replace('-', '').replace('+', '')
  return (
    <Tooltip className="phone__number" title={phoneNumber} placement="bottom">
      <a className="phone__number__link" href={`tel:${phoneLink}`}>
        <FontAwesomeIcon
          icon={faPhone}
          color={gray7}
          style={{ fontSize: '16px', marginRight: iconOnly ? '0px' : '12px' }}
        />
        {!iconOnly && <p>{phoneNumber}</p>}
      </a>
    </Tooltip>
  )
}

export const CircleButtonPhoneNumberView = ({
  phoneNumber,
}: PhoneNumberViewProps) => (
  <Tooltip className="phone__number" title={phoneNumber} placement="bottom">
    <a
      className="phone__number__link"
      href={`tel:${phoneNumber.replace('-', '').replace('+', '')}`}
      style={{
        borderRadius: '50%',
        border: '1px solid',
        borderColor: gray7,
        width: 36,
        height: 36,
        marginRight: 12,
      }}
    >
      <FontAwesomeIcon
        icon={faPhone}
        color={gray7}
        style={{ fontSize: '16px', margin: 'auto' }}
      />
    </a>
  </Tooltip>
)

export const PhoneNumberView = ({
  phoneNumber,
  iconOnly,
  displayType = 'compact',
}: PhoneNumberViewProps) => {
  if (!phoneNumber) return null
  if (displayType === 'compact')
    return (
      <CompactPhoneNumberView phoneNumber={phoneNumber} iconOnly={iconOnly} />
    )
  return <CircleButtonPhoneNumberView phoneNumber={phoneNumber} />
}
