import { formatUsc, Guid, isNullish, R } from '@breezy/shared'
import { Button } from 'antd'
import classNames from 'classnames'
import React, {
  DOMAttributes,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useScrollbarWidth } from 'react-use'
import { ResizedImage } from '../../../../components/ResizedImage/ResizedImage'
import useIsMobile from '../../../../hooks/useIsMobile'
import {
  OnResize,
  useResizeObserver,
} from '../../../../hooks/useResizeObserver'
import { BasicEstimateOption } from '../../BasicEstimateOption'
import { Option } from '../../estimatesFlowUtils'
import { Steppers } from './Steppers'
import { PresentCarouselProps } from './utils'

const CONSTANTS = {
  IMAGE: {
    // Max width of the image. Duh
    MAX_WIDTH: 600,
    // Distance from the top to offset the image
    BASE_TOP_OFFSET: 0,
    // When you scroll, the image gets smaller and becomes transparent
    SCROLL_SHRINK: {
      // The opacity when you're fully scrolled down
      START_OPACITY: 0.9,
      // The final opacity
      END_OPACITY: 0.1,
      // The scale of the size that you end up with when you fully scroll
      SCALE_FACTOR: 0.8,
    },
    // When you switch between cards, the image for the first card shrinks down and the image for the new one pops up.
    GENIE_EFFECT: {
      // The scale of the size that the image starts at when it comes out of the genie lamp (and goes back down to when
      // it goes back in).
      SCALE_FACTOR: 0.8,
      // When the image goes into the genie bottle, it goes "down". The amount it goes down is proportional to the size
      // of the image. This is that proportion.
      TRANSLATION_FACTOR: 0.5,
    },
  },
  CARD: {
    // Max card width
    MAX_WIDTH: 754,
    // Margin between the bottom of the card and the bottom of the container
    BOTTOM_MARGIN: 16,
    // Minimum distance between the top of the card and the top of the container when the container isn't scrolled. This
    // may be bigger if the card is small.
    MIN_TOP_MARGIN: {
      MOBILE: 240,
      NON_MOBILE: 370,
    },
    // Space between the card and the sides of the container. If the space available is greater than the max width of
    // the card, that distance will be used instead of these.
    MIN_SIDE_MARGIN: {
      MOBILE: 16,
      NON_MOBILE: 40,
    },
    TRANSITION_DURATION_MS: 500,
    GUTTER: {
      MOBILE: 8,
      NON_MOBILE: 12,
    },
  },
} as const

type CarouselItemProps = {
  option: Option
  itemWidth: number
  containerHeight: number
  containerTop: number
  index: number
  currentIndex: number
  onOptionSelect?: (optionGuid: Guid) => void
  isResettingScrolling: boolean
  setScrollThreshold: (index: number, value: number) => void
}

const CarouselItem = React.memo<CarouselItemProps>(
  ({
    option,
    itemWidth,
    containerHeight,
    containerTop,
    index,
    onOptionSelect,
    currentIndex,
    isResettingScrolling,
    setScrollThreshold,
  }) => {
    const isMobile = useIsMobile()

    const cardRef = useRef<HTMLDivElement>(null)

    const [cardHeight, setCardHeight] = useState(0)

    const onResize = useCallback<OnResize>(({ height }) => {
      setCardHeight(height)
    }, [])

    useResizeObserver(cardRef, onResize)

    // For large cards, the top margin will be our constants. Otherwise, it will be a value such that the card is
    // aligned to the bottom of the container.
    const [topMargin, bottomMargin] = useMemo(() => {
      const minMargin =
        CONSTANTS.CARD.MIN_TOP_MARGIN[isMobile ? 'MOBILE' : 'NON_MOBILE']

      // If the card is smaller than the container, instead of locking at the bottom (plus the padding) we continue so
      // it becomes centered on the page.
      if (cardHeight < containerHeight) {
        const availableSpace = containerHeight - cardHeight

        // After we scroll all the way down, we want the space above and below to be this (so it's centered).
        const padding = availableSpace / 2
        // If the card is small enough that if we offset it at the minimum margin it wouldn't extend to the bottom of
        // the page, we need to extend it further.
        if (availableSpace > minMargin) {
          // If we are so small that the min margin won't cover it, use the full available space so we're that far from
          // the top, but minus the bottom padding so we still have that little padding below
          const topMargin = availableSpace - CONSTANTS.CARD.BOTTOM_MARGIN

          return [topMargin, padding]
        } else {
          // Because of the if condition, we know that if we offset this by the min margin, it will be off the page. So
          // then they can just scroll up and stop at the bottom of the card plus the spacing we want (padding). And
          // mathematically we know at that point it will be the same space between the top of the card and the top of
          // the container.
          return [minMargin, padding]
        }
      } else {
        // We assume that if the card is larger than the container, we know the top margin won't be more than the minimum
        return [minMargin, CONSTANTS.CARD.BOTTOM_MARGIN]
      }
    }, [cardHeight, containerHeight, isMobile])

    useEffect(() => {
      // NGL I don't really know why this is the correct number, but it is.
      setScrollThreshold(index, topMargin - bottomMargin)
    }, [bottomMargin, index, setScrollThreshold, topMargin])

    const [effectiveIndex, setEffectiveIndex] = useState(currentIndex)

    useEffect(() => {
      if (!isResettingScrolling) {
        setEffectiveIndex(currentIndex)
      }
    }, [currentIndex, isResettingScrolling])

    const isCurrentCard = index === currentIndex
    const isEffectiveCurrentCard = index === effectiveIndex

    const marginLeft = useMemo(() => {
      const gutter = CONSTANTS.CARD.GUTTER[isMobile ? 'MOBILE' : 'NON_MOBILE']
      if (index === currentIndex - 1) {
        return -itemWidth - gutter
      }
      if (index < currentIndex) {
        return (-itemWidth - gutter) * 2
      }
      if (index === currentIndex + 1) {
        return itemWidth + gutter
      }
      if (index > currentIndex) {
        return (itemWidth + gutter) * 2
      }
      return 0
    }, [currentIndex, index, isMobile, itemWidth])

    const [containerClassName, containerStyle] = useMemo<
      [string, React.CSSProperties]
    >(() => {
      const classes = ['carousel-item ease-in-out']
      const style: React.CSSProperties = {
        paddingTop: `${topMargin}px`,
        paddingBottom: `${bottomMargin}px`,
        width: `${itemWidth}px`,
        maxWidth: `${itemWidth}px`,
        minWidth: `${itemWidth}px`,
        transitionProperty: 'opacity, margin-left',
        transitionDuration: `${CONSTANTS.CARD.TRANSITION_DURATION_MS}ms`,
        marginLeft: `${marginLeft}px`,
      }

      if (
        // The card that is being scrolled into view
        isCurrentCard &&
        !isEffectiveCurrentCard
      ) {
        classes.push('fixed', 'overflow-hidden')
        style.top = `${containerTop}px`
        style.maxHeight = `${containerHeight + bottomMargin}px`
      } else if (
        // The cards that are being scrolled out of view
        isResettingScrolling
      ) {
        classes.push('absolute')
        style.top = 0
      } else if (
        // The current card when the scrolling has stopped
        isCurrentCard
      ) {
        classes.push('absolute')
        style.top = 0
      } else {
        // The other cards when scrolling has stopped
        classes.push('fixed', 'overflow-hidden')
        style.top = `${containerTop}px`
      }
      if (isCurrentCard) {
        classes.push('z-20')
      } else {
        classes.push('z-0', 'opacity-50')
      }

      return [classNames(classes), style]
    }, [
      bottomMargin,
      containerHeight,
      containerTop,
      isCurrentCard,
      isEffectiveCurrentCard,
      isResettingScrolling,
      itemWidth,
      marginLeft,
      topMargin,
    ])

    return (
      <div className={containerClassName} style={containerStyle}>
        <div
          ref={cardRef}
          className="h-fit rounded-xl border border-t-0 border-solid border-bz-gray-500 shadow-secondary"
        >
          <div
            className={classNames(
              'sticky top-0 z-30 mx-[-1px] h-2.5 overflow-hidden rounded-t-xl border border-b-0 border-solid border-bz-gray-500 bg-white',
            )}
          >
            <div
              className={classNames(
                'h-2.5',
                option.recommended ? 'bg-bz-primary' : 'bg-bz-border-secondary',
              )}
            />
          </div>

          <BasicEstimateOption
            index={index}
            customerFacing
            className="min-h-full"
            option={option}
            showPromoPrequal
            noBorder
            hideFeaturedThumbnail
          />
          <div
            className={classNames(
              'sticky bottom-0 z-20 rounded-b-xl border-0 border-t border-solid border-bz-border bg-white',
              isMobile ? 'px-4 pb-4 pt-3' : 'px-6 pb-6 pt-4',
            )}
          >
            <Button
              block
              size="large"
              type="primary"
              disabled={!onOptionSelect}
              onClick={() => onOptionSelect?.(option.optionGuid)}
            >
              Select Option {index + 1} ({formatUsc(option.totalUsc)})
            </Button>
          </div>
        </div>
      </div>
    )
  },
)

type OptionImageProps = {
  isCurrentCard: boolean
  scrollAmount: number
  scrollThreshold: number
  imageWidth: number
  top: number
  cdnUrl?: string
}

const OptionImage = React.memo<OptionImageProps>(
  ({
    isCurrentCard,
    scrollAmount,
    scrollThreshold,
    imageWidth,
    cdnUrl,
    top,
  }) => {
    const genieEffectStyle = useMemo(() => {
      if (isCurrentCard) {
        return {
          transform: 'scale(1) translateY(0)',
          opacity: 1,
        }
      } else {
        return {
          transform: `scale(${
            CONSTANTS.IMAGE.GENIE_EFFECT.SCALE_FACTOR
          }) translateY(${
            imageWidth * CONSTANTS.IMAGE.GENIE_EFFECT.TRANSLATION_FACTOR
          }px)`,
          opacity: 0,
        }
      }
    }, [imageWidth, isCurrentCard])

    const scrollEffectStyle = useMemo(() => {
      let scrollScalar = Math.min(1, scrollAmount / scrollThreshold)
      if (isNaN(scrollScalar)) {
        scrollScalar = 0
      }
      const scale =
        1 - (1 - CONSTANTS.IMAGE.SCROLL_SHRINK.SCALE_FACTOR) * scrollScalar

      const opacity =
        CONSTANTS.IMAGE.SCROLL_SHRINK.START_OPACITY -
        (CONSTANTS.IMAGE.SCROLL_SHRINK.START_OPACITY -
          CONSTANTS.IMAGE.SCROLL_SHRINK.END_OPACITY) *
          scrollScalar

      return {
        transform: `scale(${scale})`,
        opacity,
      }
    }, [scrollAmount, scrollThreshold])

    return (
      <div
        className="absolute inset-x-0 z-10 h-10"
        style={{
          top: `${top}px`,
          minHeight: `${imageWidth}px`,
        }}
      >
        <div style={scrollEffectStyle}>
          <ResizedImage
            width={imageWidth}
            height={imageWidth}
            className="mx-auto transition-all duration-500"
            style={genieEffectStyle}
            cdnUrl={cdnUrl}
          />
        </div>
      </div>
    )
  },
)

type PresentCarouselPortraitProps = PresentCarouselProps & {
  itemWidth: number
  sidePadding: number
  containerWidth: number
}

export const PresentCarouselPortrait = React.memo<PresentCarouselPortraitProps>(
  ({
    options,
    onOptionSelect,
    header,
    itemWidth: externalItemWidth,
    sidePadding,
    containerWidth,
  }) => {
    const [hasScrollBar, setHasScrollBar] = useState(false)
    const scrollbarWidth = useScrollbarWidth()

    const itemWidth = useMemo(() => {
      if (hasScrollBar) {
        return externalItemWidth - (scrollbarWidth ?? 0)
      }
      return externalItemWidth
    }, [externalItemWidth, hasScrollBar, scrollbarWidth])

    const [currentIndex, setCurrentIndex] = useState(0)

    const containerRef = useRef<HTMLDivElement>(null)
    const headerRef = useRef<HTMLDivElement>(null)

    const [containerHeight, setContainerHeight] = useState(0)
    const [containerTop, setContainerTop] = useState(0)

    const [headerHeight, setHeaderHeight] = useState(0)

    const onResize = useCallback<OnResize>(({ height }) => {
      setContainerHeight(height)
      if (headerRef.current) {
        setHeaderHeight(headerRef.current.getBoundingClientRect().height)
      }

      setCurrentIndex(0)

      if (!containerRef.current) {
        return
      }
      setContainerTop(containerRef.current.getBoundingClientRect().top)
      setHasScrollBar(
        containerRef.current.scrollHeight > containerRef.current.clientHeight,
      )
      containerRef.current.scrollTo({
        left: 0,
        top: 0,
      })
    }, [])

    useResizeObserver(containerRef, onResize)

    const [scrollAmount, setScrollAmount] = useState(0)

    const onScroll = useCallback<
      NonNullable<DOMAttributes<HTMLDivElement>['onScroll']>
    >(e => {
      const scrollTop = (e.target as HTMLDivElement).scrollTop
      setScrollAmount(scrollTop)
    }, [])

    const resettingTimeoutRef = useRef<NodeJS.Timeout>()

    const [isResettingScrolling, setIsResettingScrolling] = useState(false)

    const [preventOverscroll, setPreventOverscroll] = useState(false)

    const scrollToIndex = useCallback((index: number) => {
      setPreventOverscroll(true)
      // This timeout is because iOS sucks. If it's over-scrolling when you hit "next", it will not animate properly. So
      // we need to set `overscroll-none` when we want to change, then wait 100ms for that to paint and THEN scroll.
      setTimeout(() => {
        setIsResettingScrolling(true)
        setCurrentIndex(index)
        if (!isNullish(resettingTimeoutRef.current)) {
          clearTimeout(resettingTimeoutRef.current)
        }
        resettingTimeoutRef.current = setTimeout(() => {
          setIsResettingScrolling(false)
          setPreventOverscroll(false)
        }, CONSTANTS.CARD.TRANSITION_DURATION_MS)
        if (!containerRef.current) {
          return
        }
        containerRef.current?.scrollTo({
          top: 0,
          behavior: 'smooth',
        })
      }, 100)
    }, [])

    const imageWidth = useMemo(
      () =>
        Math.min(CONSTANTS.IMAGE.MAX_WIDTH, containerWidth - sidePadding * 2),
      [containerWidth, sidePadding],
    )

    // This is bad React, but I don't have much of a choice. I need to scale the images based on how much we've
    // scrolled. However, that range depends on the size of the card. If it's a card that's to small to fill the full
    // container, we need to scale the image down much quicker. The number I need (the amount I need to scroll to be at
    // "the end") comes from the card heights, which I compute in the individual card components. I need to do it here
    // because I use a resize listener (the cards can change size if the details are expanded, etc). So whenever those
    // values change I'll have the child update the parent. In React you aren't supposed to have the child talk to the
    // parent like this, but I don't have much of a choice.
    const [scrollThresholds, setScrollThresholds] = useState<number[]>(() =>
      new Array(options.length).fill(0),
    )

    const setScrollThreshold = useCallback(
      (index: number, value: number) =>
        setScrollThresholds(R.update(index, value)),
      [],
    )

    const resolvedHeader = header ?? <div className="pt-4" />

    return (
      <div className="flex h-full flex-col">
        <div ref={headerRef} className="sticky top-0 z-20">
          {resolvedHeader}
        </div>

        {options.map((option, index) => (
          <OptionImage
            key={option.optionGuid}
            isCurrentCard={index === currentIndex}
            scrollAmount={scrollAmount}
            scrollThreshold={scrollThresholds[index]}
            imageWidth={imageWidth}
            top={headerHeight + CONSTANTS.IMAGE.BASE_TOP_OFFSET}
            cdnUrl={option.featuredPhotoCdnUrl}
          />
        ))}

        <div
          ref={containerRef}
          className={classNames(
            'relative z-20 flex-1 overflow-y-auto overflow-x-hidden',
            {
              'overscroll-none': preventOverscroll,
            },
          )}
          style={{
            padding: `0 ${sidePadding}px`,
          }}
          onScroll={onScroll}
        >
          {options.map((option, index) => (
            <CarouselItem
              key={option.optionGuid}
              option={option}
              index={index}
              currentIndex={currentIndex}
              containerTop={containerTop}
              itemWidth={itemWidth}
              onOptionSelect={onOptionSelect}
              containerHeight={containerHeight}
              isResettingScrolling={isResettingScrolling}
              setScrollThreshold={setScrollThreshold}
            />
          ))}
        </div>
        <Steppers
          currentIndex={currentIndex}
          totalSteps={options.length}
          scrollToIndex={scrollToIndex}
        />
      </div>
    )
  },
)
