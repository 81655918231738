import { useQuery } from 'urql'
import { gql } from '../../../generated'
import { useExpectedCompanyGuid } from '../../../providers/PrincipalUser'
const GET_PRICEBOOK_TAX_RATES_ADMIN_QUERY = gql(/* GraphQL */ `
  query GetPricebookTaxRatesAdmin($companyGuid: uuid!) {
    pricebookTaxRates(where: { companyGuid: { _eq: $companyGuid } }) {
      pricebookTaxRateGuid
      companyGuid
      name
      rate
      isActive
      version
    }
  }
`)

export const useGetPricebookTaxRatesAdmin = () => {
  const companyGuid = useExpectedCompanyGuid()
  const getPricebookTaxRatesAdminQuery = useQuery({
    query: GET_PRICEBOOK_TAX_RATES_ADMIN_QUERY,
    variables: { companyGuid: companyGuid },
  })

  const { data, fetching, error } = getPricebookTaxRatesAdminQuery[0]
  return {
    getPricebookTaxRatesAdminQuery,
    data,
    fetching,
    error,
  }
}
