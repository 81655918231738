import { zodResolver } from '@hookform/resolvers/zod'
import { createTsForm } from '@ts-react/form'
import { Button, Divider, Form } from 'antd'
import React, { useCallback, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { z } from 'zod'
import { trpc } from '../../../hooks/trpc'
import { useCompanyUserContext } from '../../../hooks/useCompanyUserContext'
import AccountReminderAssignmentField from '../AccountReminderAssignmentField/AccountReminderAssignmentField'
import { Reminder } from '../AccountReminders.types'
import { useAccountRemindersContext } from '../AccountRemindersWrapper/AccountRemindersWrapper'
import DatePickerField from '../DatePickerField/DatePickerField'
import TextField from '../TextField/TextField'
import './AccountRemindersForm.less'
import {
  AccountRemindersFormSchema,
  GuidSchema,
  IsoDateStringSchema,
} from './AccountRemindersForm.schema'

const mapping = [
  [z.string(), TextField],
  [IsoDateStringSchema, DatePickerField],
  [GuidSchema, AccountReminderAssignmentField],
] as const

type TsFormWrapperProps = React.PropsWithChildren<{
  onSubmit: () => void
  onCancel: () => void
  disabled?: boolean
  dirty?: boolean
  submitText?: string
}>

// Note: `createTsForm` won't accept something wrapped in `React.memo`
const TsFormWrapper = ({
  children,
  onSubmit,
  onCancel,
  disabled,
  dirty = false,
  submitText = 'Save',
}: TsFormWrapperProps) => {
  return (
    <Form
      onSubmitCapture={onSubmit}
      disabled={disabled}
      layout="vertical"
      className="border-0 border-b border-solid border-bz-gray-400 pb-4 pt-3"
    >
      {children}
      <Divider className="my-3" />
      <div className="flex flex-row justify-end gap-2">
        <Button htmlType="button" onClick={onCancel}>
          Cancel
        </Button>
        <Button htmlType="submit" type="primary" disabled={disabled || !dirty}>
          {submitText}
        </Button>
      </div>
    </Form>
  )
}

const TsForm = createTsForm(mapping, { FormComponent: TsFormWrapper })

type AccountRemindersFormProps = {
  hide: () => void
  submitted: () => void
  reminder?: Reminder
}

export const AccountRemindersForm = React.memo<AccountRemindersFormProps>(
  ({ reminder, hide, submitted }) => {
    const { accountGuid, disabled } = useAccountRemindersContext()

    const { timeZoneId, principalUserGuid } = useCompanyUserContext()

    const form = useForm<z.infer<typeof AccountRemindersFormSchema>>({
      resolver: zodResolver(AccountRemindersFormSchema),
    })

    const writeAccountReminder =
      trpc.accountReminders['account-reminders:write'].useMutation()

    const submitting = useMemo(
      () => writeAccountReminder.isLoading,
      [writeAccountReminder.isLoading],
    )
    const saveReminder = useCallback(
      async ({
        description,
        dueAt,
        reminderAssignmentUserGuid,
      }: z.infer<typeof AccountRemindersFormSchema>) => {
        writeAccountReminder.mutate(
          {
            accountGuid,
            description,
            dueAt,
            reminderAssignmentUserGuid,
            reminderGuid: reminder?.reminderGuid,
          },
          {
            onSuccess: () => {
              submitted()
              hide()
              form.reset()
            },
          },
        )
      },
      [
        writeAccountReminder,
        accountGuid,
        reminder?.reminderGuid,
        submitted,
        hide,
        form,
      ],
    )

    const cancel = useCallback(() => {
      hide()
      form.reset()
    }, [hide, form])

    return (
      <TsForm
        form={form}
        formProps={{
          onCancel: cancel,
          disabled: disabled || submitting,
          dirty: form.formState.isDirty,
          submitText: reminder ? 'Save Changes' : 'Save Reminder',
        }}
        schema={AccountRemindersFormSchema}
        onSubmit={saveReminder}
        defaultValues={{
          description: reminder?.description,
          dueAt: reminder?.dueAt,
          reminderAssignmentUserGuid:
            reminder?.reminderAssignments[0].user.userGuid ?? principalUserGuid,
        }}
        props={{
          dueAt: {
            timeZoneId,
          },
        }}
      >
        {/* This is some react-ts-form magic to customize the form layout */}
        {({ description, dueAt, reminderAssignmentUserGuid }) => {
          return (
            <>
              {description}
              <div className="flex w-full flex-col gap-2 md:flex-row">
                {dueAt}
                {reminderAssignmentUserGuid}
              </div>
            </>
          )
        }}
      </TsForm>
    )
  },
)
