import { PricebookTaxRateNameSchema } from '@breezy/shared'
import { Form, Input } from 'antd'

export const PricebookFormTaxRateName = () => (
  <Form.Item
    name="name"
    label="Name"
    rules={[
      {
        required: true,
        validator: async (_, value) => {
          if (!value) {
            return Promise.reject(new Error('Name is required'))
          }
          const result = PricebookTaxRateNameSchema.safeParse(value)
          if (!result.success) {
            throw new Error(
              result.error.issues[0]?.message || result.error.message,
            )
          }
        },
      },
    ]}
  >
    <Input type="text" />
  </Form.Item>
)
