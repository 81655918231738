import { PricebookTaxRateGuid } from '@breezy/shared'
import { Button, Form, Space } from 'antd'
import { useForm, useWatch } from 'antd/lib/form/Form'
import { useCallback, useState } from 'react'
import ThinDivider from '../../../../elements/ThinDivider'
import { trpc } from '../../../../hooks/trpc'
import { useExpectedCompany } from '../../../../providers/PrincipalUser'
import { useMessage } from '../../../../utils/antd-utils'
import { PricebookTaxRateFormSchema } from '../../Utils/types'
import { usePricebookAdmin } from '../../hooks/usePricebookAdmin'
import { PricebookFormItemActive } from '../FormItems/PricebookFormItemActive'
import { PricebookFormTaxRate } from '../FormItems/PricebookFormTaxRateAmount'
import { PricebookFormTaxRateName } from '../FormItems/PricebookFormTaxRateName'

const DEFAULT_VALUES: Partial<PricebookTaxRateFormSchema> = {
  name: '',
  percentage: 0,
  isActive: true,
}

export type UpsertPricebookTaxRateFormProps = {
  initialValues?: PricebookTaxRateFormSchema & {
    pricebookTaxRateGuid?: PricebookTaxRateGuid
  }
}
export const UpsertPricebookTaxRateForm = ({
  initialValues,
}: UpsertPricebookTaxRateFormProps) => {
  const message = useMessage()
  const { pricebookTaxRateMutated, closeDrawer } = usePricebookAdmin()
  const [pricebookTaxRateGuid, setPricebookTaxRateGuid] = useState(
    initialValues?.pricebookTaxRateGuid,
  )
  const company = useExpectedCompany()
  const [form] = useForm<PricebookTaxRateFormSchema>()
  const savePricebookItemMutation =
    trpc.pricebook['pricebook:save-tax-rate'].useMutation()

  const onFinish = useCallback(
    (values: PricebookTaxRateFormSchema) => {
      savePricebookItemMutation.mutate(
        {
          pricebookTaxRateGuid: pricebookTaxRateGuid,
          companyGuid: company.companyGuid,
          name: values.name,
          rate: values.percentage / 100,
          isActive: values.isActive,
        },
        {
          onSuccess: result => {
            if (pricebookTaxRateGuid) {
              message.success('Successfully updated Tax Rate in Pricebook')
            } else {
              message.success('Successfully added Tax Rate to Pricebook')
            }

            setPricebookTaxRateGuid(result.pricebookTaxRateGuid)
            pricebookTaxRateMutated()
          },
          onError: e => {
            if (pricebookTaxRateGuid) {
              message.error(
                'There was an error updating the Pricebook Tax Rate. Please try again.',
              )
            } else {
              message.error(
                'There was an error adding the Pricebook Tax Rate. Please try again.',
              )
            }
          },
        },
      )
    },
    [
      company.companyGuid,
      pricebookTaxRateGuid,
      pricebookTaxRateMutated,
      savePricebookItemMutation,
      message,
    ],
  )

  const onCancel = useCallback(() => {
    closeDrawer()
  }, [closeDrawer])

  const effectiveInitialValues = {
    ...DEFAULT_VALUES,
    ...(initialValues ?? {}),
  }

  const isActive = useWatch('isActive', form)
  const onActiveChange = useCallback(
    (checked: boolean) => {
      form.setFieldsValue({
        isActive: !checked,
      })
    },
    [form],
  )

  return (
    <Form
      form={form}
      initialValues={effectiveInitialValues}
      layout="vertical"
      validateTrigger="onBlur"
      onFinish={onFinish}
      disabled={savePricebookItemMutation.isLoading}
      className="space-y-4"
    >
      <div className="grid grid-cols-2 items-center gap-4">
        <div>
          <PricebookFormTaxRateName />
        </div>
        <div>
          <PricebookFormTaxRate />
        </div>
      </div>
      <ThinDivider widthPx={8} />
      <div className="grid grid-cols-2 items-center gap-4">
        <div>
          <PricebookFormItemActive value={isActive} onChange={onActiveChange} />
        </div>
        <div className="flex items-end justify-end">
          <Space>
            <Button onClick={onCancel}>Cancel</Button>
            <Button type="primary" htmlType="submit">
              {pricebookTaxRateGuid ? 'Update' : 'Add'}
            </Button>
          </Space>
        </div>
      </div>
    </Form>
  )
}
