import {
  OfficeRoutes,
  type OfficeRouteInfo,
  type TechnicianRouteInfo,
} from '@breezy/shared'
import {
  faMessageDollar,
  faQuestionCircle,
} from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  AutoComplete,
  Avatar,
  Badge,
  Button,
  Col,
  Input,
  Layout,
  Popover,
} from 'antd'
import classNames from 'classnames'
import React, { useMemo } from 'react'
import { AiOutlineUser } from 'react-icons/ai'
import { useLocation } from 'react-router-dom'
import { useSubscription } from 'urql'
import GlobalCreateButton from '../../apps/GlobalCreateButton'
import { AdminImpersonateUser } from '../../components/Admin/AdminImpersonateUser/AdminImpersonateUser'
import { CSModeSwitch } from '../../components/Admin/CSMode/CSModeSwitch'
import {
  GlobalSearchInputFacade,
  GlobalSearchModal,
} from '../../components/GlobalSearch'
import {
  JobsViewsContext,
  JobsViewsDropdown,
  useJobsViewsContextValue,
} from '../../components/JobLifecycleView'
import Notifications from '../../components/Notifications/Notifications'
import { PhoneIntegratedWidget } from '../../components/PhoneIntegrated/PhoneIntegratedWidget'
import { UserTimeClockHoverButton } from '../../components/UserTimeClockStatusCard/UserTimeClockHoverButton'
import { getConfig } from '../../config'
import { CircleButton } from '../../elements/CircleButton/CircleButton'
import { useCanUseIntegratedPhone } from '../../hooks/permission/useCanUseIntegratedPhone'
import useAppNavigation from '../../hooks/useAppNav'
import { useAuth } from '../../hooks/useAuth'
import { useFeatureFlag } from '../../hooks/useFeatureFlags'
import { useIsSmallScreen } from '../../hooks/useIsMobile'
import { useRouteTitleAsDocumentTitle } from '../../hooks/useRouteTitleAsDocumentTitle'
import { FETCH_COUNT_OF_OPEN_JOB_LEADS } from '../../pages/JobLeadsPage/JobLeads.gql'
import { usePrincipalUser } from '../../providers/PrincipalUser'
import { NotificationBanners } from '../NotificationBanner/NotificationBanners'
import { Navigation } from './Navigation'
import './Scene.less'

const { Content, Header } = Layout

type NavProps = {
  children: React.ReactNode
  header?: React.ReactNode
  routes: OfficeRouteInfo | TechnicianRouteInfo
}

const { helpCenterUrl, env } = getConfig()
const isDemo = env === 'demo'

export const Scene = React.memo<NavProps>(({ children, header, routes }) => {
  const isSmallScreen = useIsSmallScreen()
  const { user } = useAuth()
  const { principal, impersonatedUser, logoutPrincipalUser } =
    usePrincipalUser()
  const { navigateToUserProfilePage, navigateToJobLeadsPage } =
    useAppNavigation()
  const location = useLocation()
  useRouteTitleAsDocumentTitle(routes)
  const canUseIntegratedPhone = useCanUseIntegratedPhone()
  const isJobsLeadsEnabled = useFeatureFlag('job-leads')

  const impersonatingStyle = impersonatedUser ? 'impersonating' : ''

  const jobsViewsContextValue = useJobsViewsContextValue()

  const [{ data: countOfOpenJobLeadsData }] = useSubscription({
    query: FETCH_COUNT_OF_OPEN_JOB_LEADS,
    pause: !principal?.company?.companyGuid,
    variables: {
      companyGuid: principal?.company?.companyGuid ?? '',
    },
  })

  const AvatarMenu = (
    <div style={{ minWidth: '200px' }}>
      <div>
        <Button
          id="logout"
          type="link"
          onClick={() => logoutPrincipalUser()}
          style={{ padding: 0 }}
        >
          Log Out
        </Button>
      </div>
      <div>
        <Button
          id="settings"
          type="link"
          onClick={() => {
            navigateToUserProfilePage()
          }}
          style={{ padding: 0 }}
        >
          Settings
        </Button>
      </div>
      <AdminImpersonateUser />
      <CSModeSwitch />
    </div>
  )

  const effectiveJobLeadCount = useMemo(
    () => countOfOpenJobLeadsData?.jobLeadsAggregate?.aggregate?.count ?? 0,
    [countOfOpenJobLeadsData?.jobLeadsAggregate?.aggregate?.count],
  )

  return (
    // TODO: this is not an appropriate place for this. The "Scene" shouldn't need to know about any specifics about the
    // jobs page. https://getbreezyapp.atlassian.net/browse/BZ-839
    <JobsViewsContext.Provider value={jobsViewsContextValue}>
      <Layout className="nav" hasSider>
        {principal?.company && <GlobalSearchModal />}
        <Navigation />
        <Layout style={{ zIndex: 1 }}>
          <Header
            className={classNames(
              'nav__header flex flex-row items-center overflow-x-auto overflow-y-hidden leading-4',
              impersonatingStyle,
            )}
          >
            {location.pathname === OfficeRoutes.JOBS_V2.path ||
            location.pathname === OfficeRoutes.SCHEDULE.path ||
            header ? (
              <Col
                flex="auto"
                className="ml-5 flex items-center text-xl font-semibold text-bz-gray-900"
              >
                {header ? <>{header}</> : null}
                {/* TODO: this is not an appropriate place for this. The "Scene" shouldn't need to know anything about
                      the jobs page. A better option is to modify our routes to accept a "header" component or
                      something. https://getbreezyapp.atlassian.net/browse/BZ-839 */}
                {location.pathname === OfficeRoutes.JOBS_V2.path &&
                  principal?.company?.companyGuid && (
                    <div className="ml-6">
                      <JobsViewsDropdown />
                    </div>
                  )}
              </Col>
            ) : null}

            {(impersonatedUser && !isSmallScreen && !isDemo && (
              <div className="impersonating__text flex-1">
                {`You are impersonating ${impersonatedUser.firstName} ${impersonatedUser.lastName}`}
              </div>
            )) || <Col flex="auto" />}
            <Col className="center-children-vh my-auto">
              <div className="no-flex-wrap row center-items-v my-auto flex w-full flex-row">
                <Col flex="auto">
                  <AutoComplete
                    disabled
                    popupMatchSelectWidth={252}
                    style={{
                      display: 'none',
                    }}
                  >
                    <Input.Search
                      size="middle"
                      placeholder="Search..."
                      enterButton
                      disabled
                    />
                  </AutoComplete>
                </Col>
                {principal?.company && (
                  <Col className="nav__top-right">
                    <GlobalSearchInputFacade />
                  </Col>
                )}
                <Col className="nav__top-right">
                  <GlobalCreateButton />
                </Col>
                {principal?.company && canUseIntegratedPhone && (
                  <CircleButton className="hover:bg-white active:bg-white">
                    <PhoneIntegratedWidget />
                  </CircleButton>
                )}
                {principal?.company && (isDemo || isJobsLeadsEnabled) && (
                  <CircleButton
                    className="hover:bg-white active:bg-white"
                    tooltip={
                      effectiveJobLeadCount === 1
                        ? '1 Open Job Lead'
                        : `${effectiveJobLeadCount} Job Leads`
                    }
                    onClick={navigateToJobLeadsPage}
                  >
                    <Badge
                      showZero={false}
                      dot
                      count={
                        countOfOpenJobLeadsData?.jobLeadsAggregate?.aggregate
                          ?.count ?? 0
                      }
                    >
                      <FontAwesomeIcon
                        icon={faMessageDollar}
                        className="grey8 text-[18px]"
                      />
                    </Badge>
                  </CircleButton>
                )}
                {principal?.company && (
                  <CircleButton>
                    <Notifications />
                  </CircleButton>
                )}
                {principal?.company && <UserTimeClockHoverButton />}
                <CircleButton href={helpCenterUrl} tooltip="Help Center">
                  <FontAwesomeIcon
                    icon={faQuestionCircle}
                    className="grey8 text-[18px]"
                  />
                </CircleButton>
                <Col className="nav__top-right">
                  <Popover
                    title={principal?.emailAddress || 'Settings'}
                    placement="bottomRight"
                    content={AvatarMenu}
                    mouseLeaveDelay={0.5}
                    className="h-[36px] w-[36px]"
                  >
                    {user?.avatar ? (
                      <Avatar
                        src={
                          <img
                            id="avatar"
                            className="avatar"
                            src={user.avatar}
                            style={{ width: 36, height: 36 }}
                            alt="Avatar"
                          />
                        }
                      />
                    ) : (
                      <AiOutlineUser />
                    )}
                  </Popover>
                </Col>
              </div>
            </Col>
          </Header>

          <Content className="nav__content">
            <NotificationBanners />
            {children}
          </Content>
        </Layout>
      </Layout>
    </JobsViewsContext.Provider>
  )
})
