import { IsoDateString, guidSchema } from '@breezy/shared'
import { createUniqueFieldSchema } from '@ts-react/form'
import { z } from 'zod'

export const IsoDateStringSchema = createUniqueFieldSchema(
  z
    .string({ required_error: 'Must add a due date for the reminder.' })
    .nonempty({ message: 'Due date cannot be empty.' })
    .transform(data => data as IsoDateString),
  'dueAt',
)

export const GuidSchema = createUniqueFieldSchema(guidSchema, 'guid')

export const AccountRemindersFormSchema = z.object({
  description: z
    .string({ required_error: 'Must add a description for the reminder.' })
    .nonempty({ message: 'Description cannot be empty.' })
    .describe('Reminder'),
  dueAt: IsoDateStringSchema.describe('Due Date'),
  reminderAssignmentUserGuid: GuidSchema.describe('Assign To'),
})
