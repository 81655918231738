import { bzExpect, nextGuid } from '@breezy/shared'
import React, { useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useQuery } from 'urql'
import { useQueryParam } from 'use-query-params'
import { OnsitePageLoader } from '../../adam-components/OnsitePage/OnsitePageLoader'
import { useMaintenancePlanJobOnsiteDiscount } from '../../hooks/useMaintenancePlanJobDiscount'
import { EstimateEditView } from './EstimateEditView'
import { RELEVANT_NEW_ESTIMATE_DATA_QUERY } from './EstimatesFlow.gql'
import {
  EstimatesContext,
  JOB_APPOINTMENT_GUID_QUERY_PARAM,
  useRelevantEstimateData,
} from './estimatesFlowUtils'

export const EstimateCreationPage = React.memo(() => {
  const jobGuid = bzExpect(
    useParams().jobGuid,
    'jobGuid',
    'JobGuid is required',
  )

  const newEstimateGuid = useMemo(() => nextGuid(), [])

  const {
    companyDefaultTaxRate,
    isFetching: isRelevantDataFetching,
    ...relevantData
  } = useRelevantEstimateData()

  const mpOnsiteDiscounts = useMaintenancePlanJobOnsiteDiscount({ jobGuid })

  // urql's `useQuery` will infuriatingly re-run when we do a mutation, which I really don't want because the whole page
  // will remount. So this tells it to cut it out after its initial fetch.
  const [isPaused, setIsPaused] = useState(false)

  const [
    {
      data: relevantNewEstimateData,
      fetching: fetchingRelevantNewEstimateData,
    },
  ] = useQuery({
    query: RELEVANT_NEW_ESTIMATE_DATA_QUERY,
    variables: {
      jobGuid,
    },
    pause: isPaused,
  })

  useEffect(() => {
    if (relevantNewEstimateData) {
      setIsPaused(true)
    }
  }, [relevantNewEstimateData])

  const [jobAppointmentGuid] = useQueryParam<string>(
    JOB_APPOINTMENT_GUID_QUERY_PARAM,
  )

  const isLoading = isRelevantDataFetching || fetchingRelevantNewEstimateData

  if (isLoading) {
    return <OnsitePageLoader />
  }

  return (
    <EstimatesContext.Provider
      value={{
        estimateGuid: newEstimateGuid,
        jobGuid,
        accountGuid: relevantNewEstimateData?.jobsByPk?.accountGuid ?? '',
        locationGuid: relevantNewEstimateData?.jobsByPk?.locationGuid ?? '',
        jobAppointmentGuid,
        ...relevantData,
      }}
    >
      <EstimateEditView
        isNew
        defaultTaxRate={companyDefaultTaxRate}
        defaultDiscounts={mpOnsiteDiscounts}
        pointOfContactName={
          relevantNewEstimateData?.jobsByPk?.pointOfContact?.fullName
        }
      />
    </EstimatesContext.Provider>
  )
})
