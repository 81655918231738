import { isNullish } from '@breezy/shared'
import { Form } from 'antd'
import classNames from 'classnames'
import { ComponentProps, useMemo } from 'react'
import {
  Controller,
  ControllerProps,
  FieldErrors,
  FieldPath,
  FieldValues,
} from 'react-hook-form'
import { typedMemo } from '../../utils/react-utils'
export type ReactHookFormItemProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = ControllerProps<TFieldValues, TName> & {
  errors: FieldErrors<FieldValues>
  label?: React.ReactNode
  labelCol?: ComponentProps<typeof Form.Item>['labelCol']
  labelPosition?: 'above' | 'right'
  wrapperCol?: ComponentProps<typeof Form.Item>['wrapperCol']
  required?: boolean
  className?: string
  noBottomMargin?: boolean
  helperText?: string
}

export const ReactHookFormItem = typedMemo(
  <
    TFieldValues extends FieldValues = FieldValues,
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
  >({
    name,
    render,
    errors,
    label,
    labelCol,
    labelPosition = 'above',
    wrapperCol,
    required,
    className,
    noBottomMargin,
    rules,
    helperText,
    ...rest
  }: ReactHookFormItemProps<TFieldValues, TName>) => {
    let help: string | undefined = undefined
    const error = errors[name]
    if (error) {
      let lastMessage = error.message
      while (lastMessage && typeof lastMessage !== 'string') {
        lastMessage = lastMessage?.message
      }
      help = lastMessage
    }

    return (
      <Controller
        name={name}
        rules={{ required, ...rules }}
        render={args => {
          const formItemContent = useMemo(() => {
            return (
              <Form.Item
                className={classNames('mb-0', className)}
                required={required}
                label={labelPosition === 'above' ? label : undefined}
                {...(labelCol && { labelCol })}
                {...(wrapperCol && { wrapperCol })}
                validateStatus={
                  isNullish(errors[name]?.message) ? 'success' : 'error'
                }
                help={help}
              >
                {render(args)}
              </Form.Item>
            )
          }, [args])

          return (
            <div
              className={`flex flex-col gap-y-2 ${
                noBottomMargin ? 'mb-0' : 'mb-4'
              }`}
            >
              {labelPosition === 'right' ? (
                <div className="flex flex-row gap-x-3">
                  {formItemContent}
                  {labelPosition === 'right' && label && (
                    <label
                      htmlFor={name}
                      className="mb-auto mt-auto align-middle text-[14px] leading-[22px] text-[#202020]"
                    >
                      {label}
                    </label>
                  )}
                </div>
              ) : (
                formItemContent
              )}
              {helperText && (
                <div className="letter-spacing-[-0.14px] mt-[-4px] text-[14px] leading-[22px] text-[#8c8c8c]">
                  {helperText}
                </div>
              )}
            </div>
          )
        }}
        {...rest}
      />
    )
  },
)
