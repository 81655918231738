import { Form, InputNumber } from 'antd'

export const PricebookFormTaxRate = () => (
  <Form.Item name="percentage" label="Percentage" required>
    <InputNumber
      style={{ width: '100%' }}
      defaultValue={0}
      min={0 as number}
      max={100 as number}
      formatter={value => `${value}%`}
      parser={value => (!value ? 0 : Number.parseFloat(value.replace('%', '')))}
    />
  </Form.Item>
)
