import { formatUsc } from '@breezy/shared'
import { Button } from 'antd'
import classNames from 'classnames'
import React, { useCallback, useState } from 'react'
import { ResizedImage } from '../../../../components/ResizedImage/ResizedImage'
import { BasicEstimateOption } from '../../BasicEstimateOption'
import { CAROUSEL_ITEM_WIDTH } from '../../estimatesFlowUtils'
import { Steppers } from './Steppers'
import { PresentCarouselProps } from './utils'

const RIGHT_MARGIN = 64
const LEFT_STICK_OUT_AMOUNT = 40
const RIGHT_GUTTER = 12

const IMAGE_SIZE = 600
const IMAGE_SCALE = 0.8
const IMAGE_LEFT_MARGIN = 24

type PresentCarouselLandscapeProps = PresentCarouselProps & {
  containerWidth: number
}

export const PresentCarouselLandscape =
  React.memo<PresentCarouselLandscapeProps>(
    ({ options, onOptionSelect, header, containerWidth }) => {
      const [currentIndex, setCurrentIndex] = useState(0)

      const scrollToIndex = useCallback((index: number) => {
        setCurrentIndex(index)
      }, [])

      const resolvedHeader = header ?? <div className="pt-4" />

      return (
        <div className="absolute inset-0 z-10 flex flex-col pb-2">
          <div>{resolvedHeader}</div>

          <div className="relative flex min-h-0 flex-1 flex-row bg-white pb-4">
            {options.map((option, index) => {
              let left =
                IMAGE_LEFT_MARGIN +
                (IMAGE_SIZE * IMAGE_SCALE - IMAGE_SIZE * (1 - IMAGE_SCALE)) *
                  (index - currentIndex)

              if (index !== 0) {
                left += LEFT_STICK_OUT_AMOUNT
              }
              return (
                <div
                  key={option.optionGuid}
                  className={classNames(
                    'absolute flex h-full flex-col justify-center transition-all duration-700',
                    {
                      hidden: index > currentIndex + 1,
                    },
                  )}
                  style={{
                    left: `${left}px`,
                    transform:
                      index === currentIndex
                        ? 'scale(1)'
                        : `scale(${IMAGE_SCALE})`,
                    opacity: index === currentIndex ? 0.5 : 0,
                  }}
                >
                  <ResizedImage
                    width={IMAGE_SIZE}
                    height={IMAGE_SIZE}
                    cdnUrl={option.featuredPhotoCdnUrl}
                  />
                </div>
              )
            })}
            {options.map((option, index) => {
              let left = 0

              if (index === currentIndex) {
                left =
                  containerWidth -
                  CAROUSEL_ITEM_WIDTH * (currentIndex + 1) -
                  RIGHT_MARGIN
              } else if (index > currentIndex) {
                left =
                  containerWidth -
                  CAROUSEL_ITEM_WIDTH * (currentIndex + 1) -
                  RIGHT_MARGIN +
                  RIGHT_GUTTER
              } else if (index === currentIndex - 1) {
                left =
                  -(CAROUSEL_ITEM_WIDTH * currentIndex) + LEFT_STICK_OUT_AMOUNT
              } else if (index < currentIndex) {
                left = -CAROUSEL_ITEM_WIDTH * (currentIndex + 1)
              }

              return (
                <div
                  className={classNames(
                    'relative z-10 overflow-hidden rounded-xl shadow-secondary transition-all duration-700 ease-in-out',
                    {
                      'opacity-50': index !== currentIndex,
                    },
                  )}
                  key={option.optionGuid}
                  style={{
                    minWidth: `${CAROUSEL_ITEM_WIDTH}px`,
                    maxWidth: `${CAROUSEL_ITEM_WIDTH}px`,
                    left: `${left}px`,
                  }}
                >
                  <BasicEstimateOption
                    index={index}
                    customerFacing
                    className="min-h-full"
                    option={option}
                    showPromoPrequal
                    hideFeaturedThumbnail
                    anchorFooter
                    footer={
                      <Button
                        block
                        size="large"
                        type="primary"
                        className=""
                        disabled={!onOptionSelect}
                        onClick={() => onOptionSelect?.(option.optionGuid)}
                      >
                        Select Option {index + 1} ({formatUsc(option.totalUsc)})
                      </Button>
                    }
                  />
                </div>
              )
            })}
          </div>
          <Steppers
            currentIndex={currentIndex}
            totalSteps={options.length}
            scrollToIndex={scrollToIndex}
          />
        </div>
      )
    },
  )
