import { toTitleCase, twilioPhoneNumberToBreezyPhone } from '@breezy/shared'
import { faPhone } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Badge, Popover } from 'antd'
import { useMemo } from 'react'
import { useCompanyPhoneSettings } from '../../hooks/useCompanyPhoneEnabled'
import { CallOverlay } from './CallOverlay'
import { useTwilioPhone } from './useTwilioPhone'

const connectedColor = 'green'
const busyColor = 'yellow'
const inactiveColor = 'red'

export const PhoneIntegratedWidget = () => {
  const { status, everInteracted, incomingCall, activeCall, outboundCall } =
    useTwilioPhone()
  const { settings } = useCompanyPhoneSettings()

  const inboundPhoneNumber = useMemo(() => {
    if (!settings?.phoneNumbers?.length) return undefined

    const phoneWithoutLeadSource = settings.phoneNumbers.find(
      phone => !phone.leadSourceName,
    )
    if (phoneWithoutLeadSource)
      return twilioPhoneNumberToBreezyPhone(phoneWithoutLeadSource.phoneNumber)

    return twilioPhoneNumberToBreezyPhone(settings.phoneNumbers[0].phoneNumber)
  }, [settings])

  const statusColor = useMemo(() => {
    if (incomingCall || activeCall) return busyColor
    if (status === 'connected') return connectedColor
    return inactiveColor
  }, [status, incomingCall, activeCall])

  return (
    <>
      <Popover
        title="Phone"
        placement="bottomRight"
        content={
          <div className="flex flex-col">
            {everInteracted
              ? `Status: ${toTitleCase(status)}`
              : 'Click anywhere to initialize phone'}
            <div className="mt-3 font-bold">
              {inboundPhoneNumber ? (
                <>Inbound #: {inboundPhoneNumber}</>
              ) : (
                'No inbound number configured'
              )}
            </div>
          </div>
        }
      >
        <Badge dot color={statusColor}>
          <FontAwesomeIcon icon={faPhone} />
        </Badge>
      </Popover>
      <CallOverlay
        incomingCall={incomingCall}
        activeCall={activeCall}
        outgoingCall={outboundCall}
      />
    </>
  )
}
