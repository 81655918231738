import { PricebookItemTypeEnum } from '@breezy/shared'
import { Button } from 'antd'
import { useMemo } from 'react'
import { m } from '../../../utils/react-utils'
import { UpsertPricebookDiscountForm } from '../Form/Forms/UpsertPricebookDiscountForm'
import { UpsertPricebookItemForm } from '../Form/Forms/UpsertPricebookItemForm'
import { UpsertPricebookTaxRateForm } from '../Form/Forms/UpsertPricebookTaxRateForm'
import { PricebookCategoriesTree } from '../PricebookCategoriesTree'
import {
  PricebookAdminDrawerMode,
  usePricebookAdmin,
} from './usePricebookAdmin'

import { Drawer } from 'antd'
import { AiOutlineClose } from 'react-icons/ai'
import '../PricebookAdmin.less'
import PricebookVersionHistory from '../PricebookVersionHistory'

const drawerAddModeToItemTypeMap: Partial<
  Record<PricebookAdminDrawerMode, PricebookItemTypeEnum>
> = {
  [PricebookAdminDrawerMode.ADD_SERVICE]: PricebookItemTypeEnum.SERVICE,
  [PricebookAdminDrawerMode.ADD_MATERIAL]: PricebookItemTypeEnum.MATERIAL,
  [PricebookAdminDrawerMode.ADD_EQUIPMENT]: PricebookItemTypeEnum.EQUIPMENT,
  [PricebookAdminDrawerMode.ADD_LABOR]: PricebookItemTypeEnum.LABOR,
}

const drawerEditModeToItemTypeMap: Partial<
  Record<PricebookAdminDrawerMode, PricebookItemTypeEnum>
> = {
  [PricebookAdminDrawerMode.EDIT_SERVICE]: PricebookItemTypeEnum.SERVICE,
  [PricebookAdminDrawerMode.EDIT_MATERIAL]: PricebookItemTypeEnum.MATERIAL,
  [PricebookAdminDrawerMode.EDIT_EQUIPMENT]: PricebookItemTypeEnum.EQUIPMENT,
  [PricebookAdminDrawerMode.EDIT_LABOR]: PricebookItemTypeEnum.LABOR,
} as const

export const PricebookAdminDrawerContents = m(() => {
  const { drawerMode, categories, pricebookItem, taxRateItem, discountItem } =
    usePricebookAdmin()

  if (!categories) {
    return null
  }

  if (drawerMode === PricebookAdminDrawerMode.MANAGE_CATEGORIES) {
    return <PricebookCategoriesTree categories={categories} />
  }

  if (drawerMode === PricebookAdminDrawerMode.ADD_TAX) {
    return <UpsertPricebookTaxRateForm />
  }

  if (drawerMode === PricebookAdminDrawerMode.EDIT_TAX) {
    if (!taxRateItem) {
      return null
    }
    return (
      <UpsertPricebookTaxRateForm
        initialValues={{
          ...taxRateItem,
          percentage: taxRateItem.rate * 100,
        }}
      />
    )
  }

  if (drawerMode === PricebookAdminDrawerMode.ADD_DISCOUNT) {
    return <UpsertPricebookDiscountForm />
  }

  if (drawerMode === PricebookAdminDrawerMode.EDIT_DISCOUNT) {
    if (!discountItem) {
      return null
    }

    return (
      <UpsertPricebookDiscountForm
        initialValues={{
          ...discountItem,
          discountRate: discountItem.discountRate
            ? discountItem.discountRate * 100
            : undefined,
        }}
      />
    )
  }

  if (drawerMode === PricebookAdminDrawerMode.MANAGE_VERSION_HISTORY) {
    return <PricebookVersionHistory />
  }

  const addItemType = drawerAddModeToItemTypeMap[drawerMode]
  if (addItemType) {
    return <UpsertPricebookItemForm type={addItemType} />
  }

  const editItemType = drawerEditModeToItemTypeMap[drawerMode]

  if (editItemType) {
    if (!pricebookItem) {
      return null
    }
    return (
      <UpsertPricebookItemForm
        type={editItemType}
        initialValues={{
          ...pricebookItem,
          pricebookCategoryGuid: pricebookItem.pricebookCategoryGuid,
          qboIncomeAccountId: pricebookItem.qboIncomeAccountId,
        }}
      />
    )
  }

  return null
})

export const usePricebookAdminDrawer = () => {
  const { closeDrawer, drawerMode } = usePricebookAdmin()

  const calculateDrawerTitle = (mode: PricebookAdminDrawerMode): string => {
    switch (mode) {
      case PricebookAdminDrawerMode.CLOSED:
        return ''
      case PricebookAdminDrawerMode.MANAGE_CATEGORIES:
        return 'Pricebook Categories'
      case PricebookAdminDrawerMode.ADD_SERVICE:
        return 'New Service Item'
      case PricebookAdminDrawerMode.EDIT_SERVICE:
        return 'Edit Service Item'
      case PricebookAdminDrawerMode.ADD_MATERIAL:
        return 'New Material Item'
      case PricebookAdminDrawerMode.EDIT_MATERIAL:
        return 'Edit Material Item'
      case PricebookAdminDrawerMode.ADD_EQUIPMENT:
        return 'New Equipment Item'
      case PricebookAdminDrawerMode.EDIT_EQUIPMENT:
        return 'Edit Material Item'
      case PricebookAdminDrawerMode.ADD_TAX:
        return 'New Tax Rate'
      case PricebookAdminDrawerMode.EDIT_TAX:
        return 'Edit Tax Rate'
      case PricebookAdminDrawerMode.ADD_LABOR:
        return 'New Labor Item'
      case PricebookAdminDrawerMode.EDIT_LABOR:
        return 'Edit Labor Item'
      case PricebookAdminDrawerMode.ADD_DISCOUNT:
        return 'New Discount'
      case PricebookAdminDrawerMode.EDIT_DISCOUNT:
        return 'Edit Discount'
      case PricebookAdminDrawerMode.MANAGE_VERSION_HISTORY:
        return 'Pricebook Version History'
      default:
        throw new Error(`MissingCaseException: ${mode}`)
    }
  }

  const drawer = useMemo(
    () => (
      <Drawer
        width={720}
        title={calculateDrawerTitle(drawerMode)}
        open={drawerMode !== PricebookAdminDrawerMode.CLOSED}
        onClose={closeDrawer}
        closable={false}
        extra={
          <Button type={'text'}>
            <AiOutlineClose onClick={closeDrawer} />
          </Button>
        }
        destroyOnClose
      >
        <PricebookAdminDrawerContents />
      </Drawer>
    ),
    [closeDrawer, drawerMode],
  )

  return {
    drawer,
  }
}
