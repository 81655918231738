import { BzDateFns, LocalDateString, bzExpect } from '@breezy/shared'
import { DatePickerProps } from 'antd'
import classNames from 'classnames'
import React, { useMemo } from 'react'
import DatePicker, {
  DatePickerProps as BzDatePickerProps,
} from '../../components/DatePicker/DatePicker'

type DateFieldProps = {
  className?: string
  value: LocalDateString
  format?: string
  name: string
  size?: DatePickerProps['size']
  onBlur: () => void
  onChange: (e: { target: { value: LocalDateString } }) => void
  disabledDate?: BzDatePickerProps['disabledDate']
  placeholder?: string
  disabled?: boolean
}

export const DateField = React.memo(
  React.forwardRef<HTMLInputElement, DateFieldProps>(
    (
      {
        className,
        value,
        format = 'MMM d, yyyy',
        name,
        size = 'large',
        onBlur,
        onChange,
        disabledDate,
        placeholder,
        disabled,
      },
      ref,
    ) => {
      const parsedValue = useMemo(
        () => BzDateFns.parseISO(value, BzDateFns.UTC),
        [value],
      )

      return (
        <DatePicker
          data-testid={name}
          size={size}
          name={name}
          allowClear={false}
          value={parsedValue}
          onBlur={onBlur}
          onChange={date =>
            onChange({
              target: { value: BzDateFns.formatLocalDate(bzExpect(date)) },
            })
          }
          format={format}
          className={classNames('w-full', className)}
          disabledDate={disabledDate}
          placeholder={placeholder}
          disabled={disabled}
        />
      )
    },
  ),
)
