import { formatUsc, Guid, isNullish } from '@breezy/shared'
import { Button } from 'antd'
import classNames from 'classnames'
import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { OnsitePageSection } from '../../adam-components/OnsitePage/OnsitePageSection'
import { OnsiteWhiteLabelSection } from '../../adam-components/OnsitePage/OnsiteWhiteLabelSection'
import { RightArrowButton } from '../../adam-components/RightArrowButton'
import { BehindFeatureFlag } from '../../components/BehindFeatureFlag'
import { HtmlRenderer } from '../../elements/HtmlRenderer/HtmlRenderer'
import useIsMobile, { useIsLargeScreen } from '../../hooks/useIsMobile'
import { useQueryParamFlag, useStrictContext } from '../../utils/react-utils'
import { BasicEstimateOption } from './BasicEstimateOption'
import { EstimatesGraphic } from './EstimatesGraphic'
import { DisclaimerSection } from './components'
import { Carousel } from './components/Carousel'
import { Gallery } from './components/Gallery'
import { PresentCarousel } from './components/PresentCarousel/PresentCarousel'
import {
  CAROUSEL_ITEM_WIDTH,
  EstimateDataContext,
  EstimatesContext,
  Option,
} from './estimatesFlowUtils'

type CarouselViewProps = {
  options: Option[]
  onOptionSelect?: (optionGuid: Guid) => void
  header?: React.ReactNode
}

// Our carousel needs to be in a fixed container that takes up the full width of the screen. Since this whole thing has
// a maximum width, we can't use absolute positioning and must used fixed. However, we still want to show the "status"
// and "back" buttons etc. So we'll render a "marker" that is rendered where this thing SHOULD be. That marker's y
// position will be where this thing's y position SHOULD be (aka behind the top bar). Then we can make our fixed
// container and just set the top to that value.
const CarouselView = React.memo<CarouselViewProps>(
  ({ options, onOptionSelect, header }) => {
    const isLargeScreen = useIsLargeScreen()

    const isMobile = useIsMobile()

    const markerRef = useRef<HTMLSpanElement>(null)
    const [topOffset, setTopOffset] = useState<number>()

    useLayoutEffect(() => {
      if (!markerRef.current) {
        return
      }
      const markerOffset = markerRef.current.getBoundingClientRect().top

      setTopOffset(markerOffset)
    }, [])

    const offsetHasLoaded = !isNullish(topOffset)

    const getItemWidth = useCallback(
      () =>
        isMobile
          ? // 32 gives 16px on either side
            Math.min(window.innerWidth - 32, CAROUSEL_ITEM_WIDTH)
          : CAROUSEL_ITEM_WIDTH,
      [isMobile],
    )

    const [itemWidth, setItemWidth] = useState<number>(getItemWidth())

    useEffect(() => {
      const handleResize = () => {
        setItemWidth(getItemWidth())
      }
      window.addEventListener('resize', handleResize)
      return () => window.removeEventListener('resize', handleResize)
    }, [getItemWidth])

    // If they don't don't give us a header, then we want "pt-4" at the top. But that has to be inside the carousel so
    // the misty gradients are over it. So we put a div there that's just that height so everything shifts down. If they
    // gave their own header, they can figure out themselves how they want to space it from the top.
    const resolvedHeader = useMemo(() => {
      if (header) {
        return header
      }
      return <div className="pt-4" />
    }, [header])

    return (
      <>
        <span ref={markerRef} />
        {offsetHasLoaded && (
          <>
            <div
              className={classNames(
                'column fixed inset-x-0 bottom-0 z-10 pb-2',
                {
                  'bg-bz-gray-400': offsetHasLoaded,
                },
              )}
              style={{ top: header ? 0 : `${topOffset}px` }}
            >
              <Carousel
                header={resolvedHeader}
                centerItems
                mistyMargin={isLargeScreen ? 320 : 0}
              >
                {options.map((option, index) => (
                  <div
                    key={option.optionGuid}
                    style={{ width: `${itemWidth}px` }}
                    // pb-2 is to make sure the box shadow isn't cut off
                    className="h-full max-h-full pb-2"
                  >
                    <BasicEstimateOption
                      index={index}
                      option={option}
                      anchorFooter
                      showPromoPrequal
                      customerFacing
                      footer={
                        <Button
                          block
                          size="large"
                          type="primary"
                          className=""
                          disabled={!onOptionSelect}
                          onClick={() => onOptionSelect?.(option.optionGuid)}
                        >
                          Select Option {index + 1} (
                          {formatUsc(option.totalUsc)})
                        </Button>
                      }
                    />
                  </div>
                ))}
              </Carousel>
            </div>
          </>
        )}
      </>
    )
  },
)

type EstimatePresentViewProps = {
  carouselModeQueryParamKey?: string
  onOptionSelect?: (optionGuid: Guid) => void
  carouselHeader?: React.ReactNode
  hideWhiteLabelSection?: boolean
}

export const EstimatePresentView = React.memo<EstimatePresentViewProps>(
  ({
    carouselModeQueryParamKey = 'r',
    onOptionSelect,
    carouselHeader,
    hideWhiteLabelSection,
  }) => {
    const isMobile = useIsMobile()
    const { messageHtml, options, photoRecords } =
      useStrictContext(EstimateDataContext)

    const { companyBlurb, companyName, logoUrl } =
      useStrictContext(EstimatesContext)

    const selectedOption = useMemo(
      () => options.find(option => option.selected),
      [options],
    )

    const [carouselMode, openCarouselMode, closeCarouselMode] =
      useQueryParamFlag(carouselModeQueryParamKey)

    useEffect(() => {
      if (carouselMode && !options.length) {
        closeCarouselMode()
      }
    }, [carouselMode, closeCarouselMode, options.length])

    if (carouselMode && options.length) {
      return (
        <BehindFeatureFlag
          enabledFeatureFlag="pricebook-photos"
          render={
            <PresentCarousel
              options={options}
              onOptionSelect={onOptionSelect}
              header={carouselHeader}
            />
          }
          fallback={
            <CarouselView
              header={carouselHeader}
              options={options}
              onOptionSelect={onOptionSelect}
            />
          }
          showLoaderWhileFlagsLoad
        />
      )
    }

    return (
      <div data-testid="present-estimate-view">
        {messageHtml ? (
          <OnsitePageSection title="Message">
            <HtmlRenderer htmlContent={messageHtml} />
          </OnsitePageSection>
        ) : null}
        {photoRecords.length > 0 && (
          <OnsitePageSection title="Gallery">
            <Gallery photoRecords={photoRecords} />
          </OnsitePageSection>
        )}
        <OnsitePageSection
          title={selectedOption ? 'Accepted Estimate' : 'Estimate'}
        >
          {options.length === 1 || selectedOption ? (
            <BasicEstimateOption
              index={selectedOption?.seq ?? 0}
              hideRecommendedStyling
              customerFacing
              option={selectedOption ?? options[0]}
              showPromoPrequal
              footer={
                selectedOption ? null : (
                  <Button
                    block
                    size="large"
                    type="primary"
                    className="mt-4"
                    disabled={!onOptionSelect}
                    onClick={() => onOptionSelect?.(options[0].optionGuid)}
                  >
                    Accept Estimate
                  </Button>
                )
              }
            />
          ) : (
            <div
              className={classNames(
                'relative rounded-lg border-2 border-solid border-bz-gray-400 text-bz-gray-1000',
                isMobile ? 'p-4' : 'p-6',
              )}
            >
              <div className={classNames({ 'max-w-[60%]': !isMobile })}>
                <div className="mb-2 text-xl font-semibold">
                  Review estimates
                </div>
                <div
                  className={classNames(
                    'text-base',
                    isMobile ? 'mb-4' : 'mb-5',
                  )}
                >
                  There are {options.length} options for you to review. Click
                  the button below to review and make a selection.
                </div>
                <RightArrowButton block={isMobile} onClick={openCarouselMode}>
                  Review Options
                </RightArrowButton>
              </div>
              <div
                className={classNames(
                  'absolute inset-y-0 right-0 z-[-1] flex h-full items-center justify-center overflow-hidden',
                  isMobile ? 'w-[30%]' : 'w-[60%]',
                )}
              >
                <EstimatesGraphic className="min-w-fit" />
                <div className="absolute inset-0 bg-gradient-to-r from-white to-75%" />
              </div>
            </div>
          )}
        </OnsitePageSection>
        {!hideWhiteLabelSection && (
          <OnsiteWhiteLabelSection
            companyBlurb={companyBlurb}
            companyName={companyName}
            logoUrl={logoUrl}
          />
        )}
        <DisclaimerSection />
      </div>
    )
  },
)
