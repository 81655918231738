import { noOp } from '@breezy/shared'
import {
  faCalendarPlus,
  faGear,
  faHouseUser,
  faMicrophone,
  faMicrophoneSlash,
  faPhoneHangup,
} from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from 'antd'
import React, { useEffect, useState } from 'react'
import FaIconButtonAdam from '../../elements/FaIconButtonAdam/FaIconButtonAdam'
import {
  CallCard,
  CallHeader,
  CallInfoBody,
  CallInfoBodyProps,
} from './CallElements'

type OutgoingCallProps = CallInfoBodyProps & {
  startedAt: Date
  onMute: () => void
  onUnmute: () => void
  onHangup: () => void
  onViewAccount?: () => void
  onCreateJob?: () => void
}

const formatDuration = (seconds: number) => {
  const hours = Math.floor(seconds / 3600)
  const minutes = Math.floor((seconds % 3600) / 60)
  const secs = seconds % 60
  const hoursString = hours > 0 ? `${hours.toString().padStart(2, '0')}:` : ''
  const minutesString =
    hours > 0 ? minutes.toString().padStart(2, '0') : minutes.toString()
  return `${hoursString}${minutesString}:${secs.toString().padStart(2, '0')}`
}

const OutgoingCallView: React.FC<OutgoingCallProps> = ({
  startedAt,
  contactName,
  phoneNumber,
  leadSourceName,
  avatarText,
  onHangup,
  onMute,
  onUnmute,
  onViewAccount,
  onCreateJob,
}) => {
  const [callDurationString, setCallDurationString] = useState('00:00:00')

  useEffect(() => {
    const timer = setInterval(() => {
      const now = new Date()
      const elapsedSeconds = Math.floor(
        (now.getTime() - startedAt.getTime()) / 1000,
      )
      setCallDurationString(formatDuration(elapsedSeconds))
    }, 1000)

    return () => clearInterval(timer)
  }, [startedAt])

  const [isMuted, setIsMuted] = useState(false)

  return (
    <CallCard
      header={
        <CallHeader
          content={
            <>
              Outgoing Call{' '}
              <span className="text-[#595959]">• {callDurationString}</span>
            </>
          }
          onClose={onHangup}
        />
      }
      body={
        <CallInfoBody
          contactIsKnown={avatarText !== '?'}
          avatarText={avatarText}
          contactName={contactName}
          leadSourceName={leadSourceName}
          phoneNumber={phoneNumber}
        />
      }
      controlBar={
        <div className="mt-4 flex justify-center gap-x-3">
          {isMuted ? (
            <Button
              type="primary"
              onClick={() => {
                onUnmute()
                setIsMuted(false)
              }}
              className="w-full bg-gray-400 hover:bg-gray-500"
            >
              <FontAwesomeIcon icon={faMicrophoneSlash} />
            </Button>
          ) : (
            <Button
              type="primary"
              onClick={() => {
                onMute()
                setIsMuted(true)
              }}
              className="w-full"
            >
              <FontAwesomeIcon icon={faMicrophone} />
            </Button>
          )}
          <div className="flex gap-x-2">
            <FaIconButtonAdam
              icon={faHouseUser}
              onClick={onViewAccount}
              tooltip="View Account"
              disabled={!onViewAccount}
            />
            <FaIconButtonAdam
              icon={faCalendarPlus}
              onClick={onCreateJob}
              tooltip="Create Job"
            />
            <FaIconButtonAdam
              icon={faGear}
              onClick={noOp}
              tooltip="Audio Settings"
              disabled
            />
          </div>
          <Button type="primary" danger onClick={onHangup} className="w-full">
            <FontAwesomeIcon icon={faPhoneHangup} />
          </Button>
        </div>
      }
    />
  )
}

export default OutgoingCallView
