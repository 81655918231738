import { R, toPlural } from '@breezy/shared'
import { Collapse } from 'antd'
import { ColumnType } from 'antd/lib/table'
import classNames from 'classnames'
import React, { useMemo } from 'react'
import { useDrop } from 'react-dnd'
import { Link } from 'react-router-dom'
import { KanbanJob } from '../../../../components/Kanban/kanbanUtils'
import { StackedAvatars } from '../../../../components/StackedAvatars/StackedAvatars'
import { TagList } from '../../../../components/Tags'
import { JobLifecycleDisplayPropertySettings } from '../../../../hooks/useJobLifecyclePersistedDisplay'
import { FlexItem, calculateFlex } from '../../../../utils/layoutUtils'
import { JobsListStatusSectionTable } from './JobsListStatusSectionTable'
import { TableJob, useJobTableContext } from './JobsTableContext'

const processTableColumns = (
  columns: ((ColumnType<TableJob> & FlexItem) | null)[],
  width: number,
): [columns: ColumnType<TableJob>[], minWidth: number] => {
  const nonNullColumns = R.filter(R.isNotNil, columns)
  const [widths, totalMinWidth] = calculateFlex(nonNullColumns, width)
  const columnsWithWidths: ColumnType<TableJob>[] = []
  for (let i = 0; i < widths.length; ++i) {
    columnsWithWidths.push({
      ...nonNullColumns[i],
      width: widths[i],
    })
  }
  return [columnsWithWidths, totalMinWidth]
}

type JobsListStatusSectionProps = {
  jobs: KanbanJob[]
  displayPropertiesSettings: JobLifecycleDisplayPropertySettings
  statusName: string
  icon: React.ReactNode
  summary?: React.ReactNode
  tableWidth: number
}

export const JobsListStatusSection = React.memo<JobsListStatusSectionProps>(
  ({
    statusName,
    icon,
    jobs,
    displayPropertiesSettings,
    summary,
    tableWidth,
  }) => {
    const { boardIdToStatusIdMap, onChange } = useJobTableContext()

    const [{ isOver, canDrop, isDragging }, drop] = useDrop(
      () => ({
        accept: 'JOBS_LIST_CARD',
        drop: (item: KanbanJob) => {
          const statusId = boardIdToStatusIdMap[item.boardId]?.[statusName]
          if (statusId) {
            onChange(item, statusId)
          }
        },
        collect: monitor => ({
          isOver: !!monitor.isOver(),
          canDrop: !!monitor.canDrop(),
          isDragging: !!monitor.getItem(),
        }),
        canDrop: (item: KanbanJob) => {
          return !!boardIdToStatusIdMap[item.boardId]?.[statusName]
        },
      }),
      [statusName, onChange, boardIdToStatusIdMap],
    )

    const [columns, minWidth] = useMemo(() => {
      const columns = [
        displayPropertiesSettings.ID
          ? {
              title: 'ID',
              dataIndex: 'displayId',
              key: 'displayId',
              flex: 0,
              minWidth: 100,
              render: (displayId, job) => (
                <Link to={`/jobs/${job.jobGuid}`}>Job #{displayId}</Link>
              ),
              sorter: R.ascend(R.prop('jobType')),
            }
          : null,
        displayPropertiesSettings['Job Type']
          ? {
              title: 'Job Type',
              dataIndex: 'jobType',
              key: 'jobType',
              flex: 2,
              minWidth: 100,
              sorter: R.ascend(R.prop('jobType')),
            }
          : null,

        displayPropertiesSettings['Account Name']
          ? {
              title: 'Account',
              dataIndex: 'accountDisplayName',
              key: 'accountDisplayName',
              flex: 3,
              minWidth: 100,
              sorter: R.ascend(R.prop('accountDisplayName')),
            }
          : null,
        displayPropertiesSettings['Location']
          ? {
              title: 'Location',
              dataIndex: 'location',
              key: 'location',
              flex: 3,
              minWidth: 100,
              sorter: R.ascend(R.prop('location')),
            }
          : null,
        displayPropertiesSettings['Next Appointment']
          ? {
              title: 'Next Appointment',
              dataIndex: 'nextAppointment',
              key: 'nextAppointment',
              flex: 1,
              minWidth: 160,
              sorter: R.ascend(R.prop('nextAppointment')),
              render: (nextAppointment, job) => (
                <div className="flex items-center">
                  {job.technicians.length ? (
                    <span className="mr-1">
                      <StackedAvatars users={job.technicians} />
                    </span>
                  ) : null}
                  <div className="flex-1">{nextAppointment}</div>
                </div>
              ),
            }
          : null,
        displayPropertiesSettings['Job Class']
          ? {
              title: 'Job Class',
              dataIndex: 'jobClassFormatted',
              key: 'jobClassFormatted',
              flex: 0,
              minWidth: 120,
              sorter: R.ascend(R.prop('jobClassFormatted')),
            }
          : null,
        displayPropertiesSettings['Point of Contact']
          ? {
              title: 'Contact',
              dataIndex: 'contactFormatted',
              key: 'contactFormatted',
              flex: 1,
              minWidth: 100,
              sorter: R.ascend(R.prop('contactFormatted')),
              render: (_, job) => (
                <div>
                  {job.contact ? (
                    <>
                      {job.contact.firstName} {job.contact.lastName} -{' '}
                      {job.contact.primaryPhoneNumber ? (
                        <a
                          href={`tel:${job.contact.primaryPhoneNumber.phoneNumber}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {job.contact.primaryPhoneNumber.phoneNumber}
                        </a>
                      ) : (
                        'No phone number'
                      )}
                    </>
                  ) : (
                    'No primary contact'
                  )}
                </div>
              ),
            }
          : null,
        displayPropertiesSettings['Tags']
          ? {
              title: 'Tags',
              dataIndex: 'tagsFormatted',
              key: 'tagsFormatted',
              flex: 0,
              minWidth: 120,
              render: (_, job) => (
                <TagList
                  tags={job.tags ?? []}
                  spacingX={1}
                  spacingY={2}
                  numTagsDisplayed={2}
                  truncateLength={20}
                />
              ),
            }
          : null,
      ] satisfies ((ColumnType<TableJob> & FlexItem) | null)[]

      const processed = processTableColumns(columns, tableWidth)

      // This adds the dragging icon to the start of the row.
      processed[0].unshift({
        title: '',
        dataIndex: 'jobGuid',
        key: 'sort',
        width: 40,
      })

      return processed
    }, [displayPropertiesSettings, tableWidth])

    return (
      <div ref={drop} className="relative">
        <div
          className={classNames(
            'border-3 pointer-events-none absolute inset-0 z-10 border-solid border-blue-600',
            canDrop && isOver ? '' : 'hidden',
          )}
        />
        {isDragging && (
          <div
            className={classNames(
              'pointer-events-none absolute inset-0 z-10 opacity-30',
              {
                'bg-red-100': !canDrop,
                'bg-daybreak-blue-100': canDrop,
              },
            )}
          />
        )}
        {/* {isDragging && !canDrop && (
          <div className="pointer-events-none absolute inset-0 z-10 bg-red-100 opacity-30" />
        )} */}
        <Collapse
          key={statusName}
          defaultActiveKey="1"
          bordered={false}
          className="bg-daybreak-blue-100"
          style={{ minWidth: `${minWidth}px` }}
          items={[
            {
              key: '1',
              label: (
                <div className="flex flex-row items-center space-x-3">
                  {icon}
                  <div className="text-base font-semibold text-bz-gray-900">
                    {statusName}
                  </div>
                  <div className="text-xs text-bz-gray-700">
                    {jobs.length} {toPlural(jobs.length, 'job')}
                  </div>
                  {summary && (
                    <div className="flex flex-1 flex-row justify-end gap-6">
                      {summary}
                    </div>
                  )}
                </div>
              ),
              children: (
                <JobsListStatusSectionTable jobs={jobs} columns={columns} />
              ),
            },
          ]}
        />
      </div>
    )
  },
)
